<template>
  <div
    :class="[
      'video-player',
      data.settings.theme === 'hero' ? 'video-player--hero' : false,
      data.settings.teaser ? 'video-player--teaser' : false,
      data.settings.full ? 'video-player--full' : false,
    ]"
  >
    <video
      ref="player"
      crossorigin
      :playsinline="data.settings.teaser"
      :data-poster="poster"
    />
  </div>
</template>

<script>
import Hls from 'hls.js';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default {
  name: 'VideoPlayer',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
      hls: null,
    };
  },
  computed: {
    poster() {
      return this.data.value?.vimeo?.pictures?.base_link;
    },
  },
  mounted() {
    const { link } = this.data.value.vimeo.files.find(
      (video) => video.quality === 'hls',
    );
    const { player } = this.$refs;

    let options = {
      storage: {
        enabled: false,
      },
    };

    if (this.data.settings.teaser) {
      options = {
        ...options,
        controls: [],
        loop: { active: true },
        autoplay: true,
        muted: true,
      };
    } else if (this.data.settings.full) {
      options = {
        ...options,
        controls: ['play', 'progress', 'current-time', 'duration', 'mute'],
        loop: { active: true },
        autoplay: true,
        enabled: !this.$mq.isSmartphone,
      };
    }

    this.plyr = new Plyr(player, options);

    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.loadSource(link);
      this.hls.attachMedia(player);

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        player.play();
      });
    } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
      player.src = link;
      player.addEventListener('loadedmetadata', () => {
        player.play();
      });
    }

    if (this.data.settings.full && !this.$mq.isSmartphone) {
      this.plyr.on('controlshidden', this.checkClose);
      this.plyr.on('controlsshown', this.checkClose);
    }
  },
  beforeDestroy() {
    if (!this.data.settings.full) {
      if (this.hls) {
        this.hls.destroy();
      }
      if (this.plyr) {
        this.plyr.destroy();
      }
    }

    if (this.data.settings.full && !this.$mq.isSmartphone) {
      this.plyr.on('controlshidden', this.checkClose);
      this.plyr.on('controlsshown', this.checkClose);
    }
  },
  methods: {
    checkClose(e) {
      const close = this.$parent.$refs.close.$el;
      if (e.type === 'controlshidden') {
        close.style.opacity = 0;
      } else {
        close.style.removeProperty('opacity');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  &--hero {
    width: 100%;
    height: 100%;
    position: absolute;

    video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--teaser {
    z-index: -1;
  }

  &--full {
    z-index: 10;
  }
}
</style>
