<template>
  <component
    :is="tag"
    :class="`input--block ${themeClass}`"
    @submit.prevent="data.fn"
  >
    <compoment
      is="input"
      :class="[
        'input',
        data.size ? `typo--${data.size}` : null,
        disabled ? 'input--disabled' : null,
      ]"
      :type="type"
      :placeholder="placeholder"
      :name="name"
      :value="value"
      :required="required"
      @input="$emit('input', $event.target.value)"
      @[event]="data.fn"
      @keyup.enter="onKeyUp"
    />
    <Link
      v-show="type === 'password'"
      :data="{
        fn: () => {},
        title: 'Enter',
        type: 'submit',
      }"
      theme="button"
      class="input--submit"
      :has-container="false"
    />
  </component>
</template>

<script>
import Link from '@/components/typo/link';

export default {
  name: 'Input',
  components: {
    Link,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    keyup: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      success: {
        type: Boolean,
        default: false,
      },
    };
  },
  computed: {
    tag() {
      return (this.type)
        && (this.type === 'password')
        ? 'form'
        : 'div';
    },

    event() {
      return this.data.fn
        ? (this.type)
          && (this.type === 'password')
          ? null
          : 'input'
        : null;
    },

    themeClass() {
      return this.theme
        ? `input--${this.theme}`
        : this.settings.theme
          ? `input--${this.settings.theme}`
          : false;
    },
    onKeyUp() {
      return this.keyup ? this.keyup.fn : null;
    },

  },
};
</script>

<style lang="scss" scoped>
input {
  outline: none;
  @extend %typo--button;
  background: none;
  border: none;
  max-width: 100%;
  flex: 1 0 0;
  max-height: 34px;
  position: relative;
  color: var(--white);
  padding: var(--spacer-xxs) var(--spacer-btn-m) var(--spacer-xs);
  text-align: inherit;
  box-sizing: border-box;

  &::placeholder {
    @extend %typo--button;
    color: var(--white);
    opacity: 1;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    background: none;
  }
}

.input {
  &--block {
    display: flex;
    width: 100%;
  }
  &--default {
    background: none;
  }

  &--form {
    border-radius: var(--border-radius);
    background: var(--bg-white);
    text-align: center;
    padding: 3px;
    // @include mq(s) {
    //   padding: 0;
    //   text-align: initial;
    //   background: none;
    // }
  }

  &--password {
    .input--submit {
      border-radius: var(--border-radius);
      background: var(--bg-gray);
    }
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  &::autofill {
    background-color: none;
  }
}
</style>
