<template>
  <Grid
    class="separator"
    :grid="$mq.isMobile ? '1' : '12'"
    :row-gap="$mq.isMobile ? 'xxxs' : 's'"
  >
    <Column :size="{ default: 1, s: 4, t: 3, m: 2 }">
      <inline-svg
        v-if="data.acf.symbol"
        :src="data.acf.symbol"
        class="symbol"
        @loaded="fillPath($event)"
      />
    </Column>
    <Column
      class="separator__info"
      :size="{ default: 1, s: 8, t: 9, m: 10 }"
    >
      <div class="separator__info--line" />
      <Title
        typo="small"
        class="separator__info--content t-ellipsis"
        :value="parseDate(data.acf.from, data.acf.until)"
      />
      <div class="separator__info--line" />
      <Title
        typo="small"
        class="separator__info--content t-ellipsis"
        :value="`${data.acf.artist ? data.acf.artist+'—' : ''}${data.acf.title}`"
      />
      <div class="separator__info--line" />
      <Title
        v-if="data.acf.project.name || data.acf.type.name"
        :value="data.acf.project.name ? data.acf.project.name : data.acf.type.name"
        typo="small"
        class="separator__info--content t-ellipsis"
      />
      <div class="separator__info--line" />
    </Column>
  </Grid>
</template>

<script>
import Title from '@/components/typo/title';
import { parseDate, fillPath } from '@/assets/js/utils';

export default {
  name: 'ThumbSeparator',
  components: {
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      parseDate,
      fillPath,
    };
  },
};
</script>

<style lang="scss" scoped>
.separator {
  color: var(--gray);
  padding-top: var(--spacer-l);
  padding-bottom: var(--spacer-xxxs);
  @include mq(s) {
    padding-top: var(--spacer-xxl);
    padding-bottom: var(--spacer-s);
  }
  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-transform: uppercase;
    text-align: center;
    @include mq(s) {
      text-align: left;
    }
    &--line {
      height: 1px;
      width: 100%;
      background: var(--gray);
    }
  }
  .symbol {
    fill: var(--gray);
  }
}
</style>
