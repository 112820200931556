<template>
  <main>
    <div class="container">
      <keep-alive>
        <Archive
          v-if="!search"
          :data="{
            value: {
              title: 'current',
              post_type: 'post',
            },
            settings: {
              filters: ['agenda:current'],
              posts_per_page: 24,
              order: true,
            },
          }"
        />
      </keep-alive>
      <keep-alive>
        <Archive
          v-if="!search"
          :data="{
            value: {
              title: 'upcoming',
              post_type: 'post',
            },
            settings: {
              filters: ['agenda:upcoming'],
              posts_per_page: 24,
              order: true,
            },
          }"
        />
      </keep-alive>
      <Archive
        v-if="!search"
        :data="{
          value: {
            title: 'past',
            post_type: 'post',
          },
          settings: {
            filters: ['agenda:past'],
            posts_per_page: 24,
            infinite_scroll: true,
            order: true,
          },
        }"
        :thumb="!$mq.isMobile ? undefined : 'small'"
      />

      <Archive
        v-if="search"
        :key="prevRequest"
        :data="{
          value: {
            post_type: 'post',
            search: search.query.search,
          },
          settings: {
            filters: filters && filters.length > 0 ? filters : null,
            posts_per_page: 12,
            infinite_scroll: true,
            order: true,
          },
        }"
        thumb="figure"
      />
      <Spacer :bottom="{default: 'l-m', s: 'xl'}" />
    </div>
    <AppFooter />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Archive from '@/components/blocks/archive';
import AppFooter from '@/components/ui/footer';

export default {
  name: 'Agenda',
  components: {
    Archive,
    AppFooter,
  },
  data() {
    return {
      prevRequest: null,
      results: [],
      found: false,
      cancelToken: null,
      filters: null,
    };
  },
  computed: {
    ...mapGetters(['search', 'findIdBySlug']),
  },
  watch: {
    search: {
      handler(val) {
        if (val && Object.keys(val.query).length > 0) {
          this.$nextTick(() => {
            this.setRequest();
          });
        } else {
          this.prevRequest = null;
          this.filters = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$store.commit('SET_SEARCH', null);
  },
  methods: {
    setRequest() {
      this.filters = [];
      const { query } = this.search;

      if (query.date) {
        this.filters.push(`agenda:${query.date}`);
      }
      if (query.type) {
        this.filters.push(
          `event_type:${this.findIdBySlug(query.type, 'event_type')}`,
        );
      }
      if (query.project) {
        this.filters.push(
          `event_project:${this.findIdBySlug(query.project, 'event_project')}`,
        );
      }
      if (query.year) {
        this.filters.push(`year:${query.year}`);
      }

      const parseParams = Buffer.from(JSON.stringify(query), 'binary').toString(
        'base64',
      );

      this.prevRequest = parseParams;
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh) * 100);
  color: var(--white);
  .container {
    flex: 1;
    padding: var(--spacer-l-m) var(--spacer-xxxs) 0;

    @include mq(s) {
      transition: padding 0.3s $ease-custom;
      padding: calc(var(--spacer-xl) + var(--filters)) var(--spacer-s) 0;
    }
  }

  a {
    display: block;
  }
}
</style>
