<template>
  <Grid
    grid="1"
    column-gap="s"
    row-gap="0"
    :class="`${
      isIndex
        ? 'gutenberg--index'
        : type === 'post'
          ? 'gutenberg--single'
          : 'gutenberg'
    }`"
  >
    <section
      v-for="(block, index) in data"
      :key="index"
      :class="[
        'section',
        `section--${parseBlockName(block.blockName)}`,
        block.attrs.bigText && block.attrs.bigText === true
          ? 'section--paragraph-bigText'
          : `section--${parseBlockName(block.blockName)}-default`,
      ]"
    >
      <Wrapper
        :boxed="isBoxed(block)"
        :size="setBlockSize(block, type)"
      >
        <component
          :is="components.get(parseBlockName(block.blockName))"
          :data="block.blockName.includes('acf/') ? block.attrs.data : block"
        />
      </Wrapper>
    </section>
  </Grid>
</template>

<script>
import { parseBlockName } from '@/assets/js/utils';
import components from '@/assets/js/components';

export default {
  name: 'Gutenberg',
  props: {
    data: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'page',
    },
    isIndex: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parseBlockName,
      components,
    };
  },
  methods: {
    isBoxed(block) {
      return !(
        parseBlockName(block.blockName) === 'gallery'
        || (parseBlockName(block.blockName) === 'image'
          && block.attrs.data.settings.theme === 'no-boxed')
        || (parseBlockName(block.blockName) === 'post'
          && block.attrs.data.settings.theme === 'index')
        || (parseBlockName(block.blockName) === 'video'
          && block.attrs.data.settings.width === 'full-bleed')
        || parseBlockName(block.blockName) === 'embed'
      );
    },
    setBlockSize(block, type) {
      return (((parseBlockName(block.blockName) === 'paragraph'
        && block.attrs.bigText !== true)
        || (parseBlockName(block.blockName) === 'image'
          && block.attrs.data.settings.theme !== 'no-boxed'))
        && type === 'post')
        || (block.attrs.bigText && block.attrs.bigText === false)
        || (parseBlockName(block.blockName) === 'video'
          && block.attrs.data.settings.width !== 'full-bleed')
        ? 'm'
        : undefined;
    },
  },
};
</script>

<style lang="scss">
.gutenberg {
  padding: var(--spacer-l-m) 0;
  @include mq(s) {
    padding: var(--spacer-xl) 0;
  }
  &--index {
    padding: 0;
    @include mq(s) {
      row-gap: 0;
    }
    .section {
      padding: 0;
    }
  }
}

.main-base--rsvp {
  .gutenberg {
    align-content: center;
  }
}

.section {
  padding-top: var(--spacer-xxxs);
  @include mq(s) {
    padding-top: var(--spacer-xs);
  }
  &:first-child {
    padding-top: 0;
  }
  // &--paragraph + &--paragraph {
  //   padding-top: 0;
  //   p:empty {
  //     padding-top: var(--spacer-xs);
  //   }
  // }

  // &--paragraph-bigText + &--paragraph-default {
  //   padding-top: var(--spacer-xs);
  // }
}
</style>
