export default {
  ADD_ITEM(state, {
    type, item, lang, context,
  }) {
    if (item && type) {
      if (!state[type][lang][item.slug] || context === 'view') {
        state[type][lang][item.slug] = { ...item, context };
      }

      if (state[type][lang][item.slug] && item.agenda && context === 'embed') {
        state[type][lang][item.slug] = {
          ...state[type][lang][item.slug],
          agenda: item.agenda,
        };
      }
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state[type].requests.push(request);
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      Object.keys(state.postTypes).forEach((key) => {
        const restBase = state.postTypes[key].rest_base;
        state[restBase][lang] = {};
      });
      state.event_type[lang] = {};
      state.event_project[lang] = {};
    });
  },
};
