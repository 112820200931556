<template>
  <div class="posts">
    <Grid
      v-if="items.length > 0 && data.settings.theme !== 'index'"
      :col="{ default: 6, s: 4 }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <component
          :is="thumbs.get('default')"
          :data="item"
        />
      </div>
    </Grid>

    <HomeScroll
      v-else-if="items.length > 0"
      :data="items"
    />
  </div>
</template>

<script>
import Thumb from '@/components/thumb';
import HomeScroll from '@/components/hero/home';

const thumbs = new Map();
thumbs.set('default', Thumb);

export default {
  name: 'Posts',
  components: {
    HomeScroll,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs,
      items: [],
    };
  },
  async created() {
    const promises = [];
    this.data.value.posts.forEach((item) => {
      promises.push(
        this.$store.dispatch('getSingleBySlug', {
          type: 'posts',
          slug: item.post.post_name,
          lang: 'default',
          context: 'embed',
        }),
      );
    });
    this.items = await Promise.all(promises);
  },
};
</script>
