<template>
  <nav
    :class="`menu btn-container ${name ? 'menu--' + name : 'menu--navigation'}`"
  >
    <Link
      v-if="name === 'navigation'"
      :data="{
        url: '/',
        title: $store.state.wp.site.name,
      }"
      class="link-home"
      theme="button"
      :has-container="false"
      :additional-themes="[name === 'navigation' ? 'link--button-menu' : '']"
    />
    <Link
      v-for="item in navigation"
      :key="item.id"
      :data="{
        url: item.url,
        title: item.content,
        target: item.target,
      }"
      theme="button"
      :has-container="false"
      :class="[...item.css]"
      :additional-themes="[name === 'navigation' ? 'link--button-menu' : '']"
    />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';

export default {
  name: 'Menu',
  components: {
    Link,
  },
  props: {
    name: {
      type: String,
      default: 'navigation',
    },
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu(this.name);
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  justify-content: space-between;

  &--navigation {
    & > * {
      &:nth-child(1) {
        flex: 1;
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
        width: 96px;
      }
      &:nth-child(3) {
        order: 3;
        width: 96px;
      }
    }
    @include mq(t) {
      width: auto;
      justify-content: initial;
      & > * {
        &:nth-child(n) {
          order: 1;
          width: auto;
          flex: auto;
        }
      }
    }
  }

  &--social {
    position: fixed;
    bottom: calc(var(--spacer-xxxs) + env(safe-area-inset-bottom));
    left: var(--spacer-xxxs);
    right: var(--spacer-xxxs);
    width: auto;
    @include mq(t) {
      position: initial;
      transform: initial;
      width: initial;
    }

    html.no-flexbox-gap & {
      @include mq($until: t){
        margin: 0 !important;
      }
    }
  }
}
</style>
