<template>
  <main class="not-found">
    <Wrapper
      boxed
      :size="undefined"
    >
      <Title value="The page you request could not be found." />
      <Link
        :data="{
          url: '/',
          title: 'Go back to the home page',
          size: 'rare',
        }"
        :has-container="false"
        class="pointer"
      />
    </Wrapper>
    <div class="desktop">
      <svg
        viewBox="0 0 1270 628"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <path
          data-name="A"
          d="M100 214c-55 0 -100 45 -100 100s45 100 100 100h1070c55 0 100 -45 100 -100s-45 -100 -100 -100h-1070z"
        />
        <path
          data-name="B"
          d="M100 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 100
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="C"
          d="M0 428v200h414v-200h-414zM0 0v200h414v-200h-414zM856 428v200h413v-200h-413zM857 0v200h413v-200h-413z"
        />
        <path
          data-name="D"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M856 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="E"
          d="M100 214c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214zM856 314c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100z"
        />
        <path
          data-name="F"
          d="M314 0c-173 0 -314 141 -314 314s141 314 314 314s314 -141 314 -314s-141 -314 -314 -314zM642 314c0 173 141 314 314 314s314 -141 314 -314s-141 -314 -314 -314s-314 141 -314 314z"
        />
        <path
          data-name="G"
          d="M214 428v200h842v-200h-842z"
        />
        <path
          data-name="H"
          d="M421 107c-114 0 -207 93 -207 207s93 207 207 207s207 -93 207 -207s-93 -207 -207 -207zM642 314c0 114 93 207 207 207s207 -93 207 -207s-93 -207 -207 -207s-207 93 -207 207z"
        />
        <path
          data-name="I"
          d="M214 214v200h842v-200h-842z"
        />
        <path
          data-name="J"
          d="M857 414v214h200v-214h213v-200h-213v-214h-200v214h-443v-214h-200v214h-214v200h214v214h200v-214h443z"
        />
        <path
          data-name="K"
          d="M314 0c-114 0 -207 93 -207 207c0 39 11 76 30 107c-19 31 -30 68 -30 107c0 114 93 207 207 207s207 -93 207 -207c0 -39 -11 -76 -30 -107c19 -31 30 -68 30 -107c0 -114 -93 -207 -207 -207zM749 207c0 39 11 76 30 107c-19 31 -30 68 -30 107c0 114 93 207 207 207
            s207 -93 207 -207c0 -39 -11 -76 -30 -107c19 -31 30 -68 30 -107c0 -114 -93 -207 -207 -207s-207 93 -207 207z"
        />
        <path
          data-name="L"
          d="M528 214c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214z"
        />
        <path
          data-name="M"
          d="M634 0c-114 0 -207 93 -207 207c0 39 11 76 30 108c-18 31 -29 67 -29 106c0 114 93 207 207 207s207 -93 207 -207c0 -39 -11 -76 -30 -108c18 -31 29 -67 29 -106c0 -114 -93 -207 -207 -207z"
        />
        <path
          data-name="N"
          d="M100 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M428 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 100
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 314
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="O"
          d="M635 0c-173 0 -314 141 -314 314s141 314 314 314s314 -141 314 -314s-141 -314 -314 -314z"
        />
        <path
          data-name="P"
          d="M100 628h1070v-200h-1070v200zM97 0v200h1070v-200h-1070z"
        />
        <path
          data-name="Q"
          d="M100 428c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214zM428 314c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM856 100c0 55 45 100 100 100h214
            c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100z"
        />
        <path
          data-name="R"
          d="M0 100v428h200v-428h-200zM1070 100v428h200v-428h-200z"
        />
        <path
          data-name="S"
          d="M528 214c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="T"
          d="M528 0c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214zM0 528c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM428 528c0 55 45 100 100 100h214c55 0 100 -45 100 -100
            s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM856 528c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100z"
        />
        <path
          data-name="U"
          d="M100 0c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M1070 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="V"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M214 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="W"
          d="M314 0c-55 0 -100 45 -100 100v428c0 55 45 100 100 100s100 -45 100 -100v-428c0 -55 -45 -100 -100 -100zM856 100v428c0 55 45 100 100 100s100 -45 100 -100v-428c0 -55 -45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="X"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M214 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="Y"
          d="M207 0c-114 0 -207 93 -207 207v214c0 114 93 207 207 207s207 -93 207 -207v-214c0 -114 -93 -207 -207 -207zM856 207v214c0 114 93 207 207 207s207 -93 207 -207v-214c0 -114 -93 -207 -207 -207s-207 93 -207 207z"
        />
        <path
          data-name="Z"
          d="M100 214c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM535 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            "
        />
        <path
          data-name="a"
          d="M100 214c-55 0 -100 45 -100 100s45 100 100 100h1070c55 0 100 -45 100 -100s-45 -100 -100 -100h-1070z"
        />
        <path
          data-name="b"
          d="M100 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 100
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="c"
          d="M0 428v200h414v-200h-414zM0 0v200h414v-200h-414zM856 428v200h413v-200h-413zM857 0v200h413v-200h-413z"
        />
        <path
          data-name="d"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M856 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="e"
          d="M100 214c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214zM856 314c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100z"
        />
        <path
          data-name="f"
          d="M314 0c-173 0 -314 141 -314 314s141 314 314 314s314 -141 314 -314s-141 -314 -314 -314zM642 314c0 173 141 314 314 314s314 -141 314 -314s-141 -314 -314 -314s-314 141 -314 314z"
        />
        <path
          data-name="g"
          d="M214 428v200h842v-200h-842z"
        />
        <path
          data-name="h"
          d="M421 107c-114 0 -207 93 -207 207s93 207 207 207s207 -93 207 -207s-93 -207 -207 -207zM642 314c0 114 93 207 207 207s207 -93 207 -207s-93 -207 -207 -207s-207 93 -207 207z"
        />
        <path
          data-name="i"
          d="M214 214v200h842v-200h-842z"
        />
        <path
          data-name="j"
          d="M857 414v214h200v-214h213v-200h-213v-214h-200v214h-443v-214h-200v214h-214v200h214v214h200v-214h443z"
        />
        <path
          data-name="k"
          d="M314 0c-114 0 -207 93 -207 207c0 39 11 76 30 107c-19 31 -30 68 -30 107c0 114 93 207 207 207s207 -93 207 -207c0 -39 -11 -76 -30 -107c19 -31 30 -68 30 -107c0 -114 -93 -207 -207 -207zM749 207c0 39 11 76 30 107c-19 31 -30 68 -30 107c0 114 93 207 207 207
            s207 -93 207 -207c0 -39 -11 -76 -30 -107c19 -31 30 -68 30 -107c0 -114 -93 -207 -207 -207s-207 93 -207 207z"
        />
        <path
          data-name="l"
          d="M528 214c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214z"
        />
        <path
          data-name="m"
          d="M634 0c-114 0 -207 93 -207 207c0 39 11 76 30 108c-18 31 -29 67 -29 106c0 114 93 207 207 207s207 -93 207 -207c0 -39 -11 -76 -30 -108c18 -31 29 -67 29 -106c0 -114 -93 -207 -207 -207z"
        />
        <path
          data-name="n"
          d="M100 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M428 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 100
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 314
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="o"
          d="M635 0c-173 0 -314 141 -314 314s141 314 314 314s314 -141 314 -314s-141 -314 -314 -314z"
        />
        <path
          data-name="p"
          d="M100 628h1070v-200h-1070v200zM97 0v200h1070v-200h-1070z"
        />
        <path
          data-name="q"
          d="M100 428c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214zM428 314c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM856 100c0 55 45 100 100 100h214
            c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100z"
        />
        <path
          data-name="r"
          d="M0 100v428h200v-428h-200zM1070 100v428h200v-428h-200z"
        />
        <path
          data-name="s"
          d="M528 214c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="t"
          d="M528 0c-55 0 -100 45 -100 100s45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214zM0 528c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM428 528c0 55 45 100 100 100h214c55 0 100 -45 100 -100
            s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM856 528c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100z"
        />
        <path
          data-name="u"
          d="M100 0c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M1070 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="v"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M214 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="w"
          d="M314 0c-55 0 -100 45 -100 100v428c0 55 45 100 100 100s100 -45 100 -100v-428c0 -55 -45 -100 -100 -100zM856 100v428c0 55 45 100 100 100s100 -45 100 -100v-428c0 -55 -45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="x"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M214 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="y"
          d="M207 0c-114 0 -207 93 -207 207v214c0 114 93 207 207 207s207 -93 207 -207v-214c0 -114 -93 -207 -207 -207zM856 207v214c0 114 93 207 207 207s207 -93 207 -207v-214c0 -114 -93 -207 -207 -207s-207 93 -207 207z"
        />
        <path
          data-name="z"
          d="M100 214c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM535 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            "
        />
        <path
          data-name="0"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M856 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="1"
          d="M421 107c-114 0 -207 93 -207 207s93 207 207 207c39 0 76 -11 107 -30c31 19 68 30 107 30s76 -11 107 -30c31 19 68 30 107 30c114 0 207 -93 207 -207s-93 -207 -207 -207c-39 0 -76 11 -107 30c-31 -19 -68 -30 -107 -30s-76 11 -107 30c-31 -19 -68 -30 -107 -30z
            "
        />
        <path
          data-name="2"
          d="M0 314c0 55 45 100 100 100s100 -45 100 -100v-214c0 -55 -45 -100 -100 -100s-100 45 -100 100v214zM428 528c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM1070 100v214c0 55 45 100 100 100s100 -45 100 -100
            v-214c0 -55 -45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="3"
          d="M0 528c0 55 45 100 100 100s100 -45 100 -100v-214c0 -55 -45 -100 -100 -100s-100 45 -100 100v214zM428 100c0 55 45 100 100 100h214c55 0 100 -45 100 -100s-45 -100 -100 -100h-214c-55 0 -100 45 -100 100zM1070 314v214c0 55 45 100 100 100s100 -45 100 -100
            v-214c0 -55 -45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="4"
          d="M214 314c0 55 45 100 100 100s100 -45 100 -100v-214c0 -55 -45 -100 -100 -100s-100 45 -100 100v214zM856 100v214c0 55 45 100 100 100s100 -45 100 -100v-214c0 -55 -45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="5"
          d="M428 0v200h414v-200h-414zM428 428v200h414v-200h-414z"
        />
        <path
          data-name="6"
          d="M314 214c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M642 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 528c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="7"
          d="M100 0c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM428 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M642 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="8"
          d="M314 428c-55 0 -100 45 -100 100s45 100 100 100s100 -45 100 -100s-45 -100 -100 -100zM0 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM214 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z
            M428 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM642 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 100c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM856 528
            c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100zM1070 314c0 55 45 100 100 100s100 -45 100 -100s-45 -100 -100 -100s-100 45 -100 100z"
        />
        <path
          data-name="9"
          d="M214 214v200h200v-200h-200zM856 214v200h200v-200h-200z"
        />
      </svg>
    </div>

    <Link
      v-show="lettersArray.length > 0"
      ref="clear"
      :data="{
        fn: clearAll,
        title: 'Clear',
      }"
      theme="button"
      class="clear"
    />
  </main>
</template>

<script>
import Title from '@/components/typo/title';
import Link from '@/components/typo/link';

export default {
  name: 'NotFound',
  components: {
    Title,
    Link,
  },
  data() {
    return {
      lett: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
      mobileArray: [],
      lettersArray: [],
      interval: {},
    };
  },
  created() {
    Object.defineProperties(this.lettersArray, {
      count: {
        value(value) {
          return this.filter((x) => x === value).length;
        },
      },
    });
    window.addEventListener('touchstart', this.fnTouchStart);
    window.addEventListener('touchend', this.fnTouchEnd);
    window.addEventListener('keydown', this.fnKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.fnKeyDown);
    window.removeEventListener('touchstart', this.fnTouchStart);
    window.removeEventListener('touchend', this.fnTouchEnd);
    clearInterval(this.interval);
    this.mobileArray = [];
    this.lettersArray = [];
    this.lett = [];
  },
  methods: {
    randLetter() {
      this.$el.querySelector(
        `[data-name=${this.lett[Math.floor(Math.random() * this.lett.length)]}]`,
      ).style.display = 'block';
      this.mobileArray.push(
        this.lett[Math.floor(Math.random() * this.lett.length)],
      );
    },
    hideLetters() {
      this.$el.querySelectorAll('path').forEach((letter) => {
        letter.style.display = 'none';
      });
      this.mobileArray = [];
      this.lettersArray = [];
    },

    clearAll() {
      this.hideLetters();
    },

    fnTouchEnd() {
      clearInterval(this.interval);
      this.hideLetters();
    },
    fnTouchStart() {
      let count = 0;
      this.interval = setInterval(() => {
        if (count < 5) {
          this.randLetter();
          count += 1;
        } else {
          this.hideLetters();
          count = 0;
        }
      }, 100);
    },
    fnKeyDown(e) {
      if (
        (e.code >= 'KeyA' && e.code <= 'KeyZ')
        || (e.code >= 'Digit0' && e.code <= 'Digit9' && !e.shiftKey)
      ) {
        this.lettersArray.push(e.key.toUpperCase().toString());
        this.$el.querySelector(
          `[data-name="${e.key.toUpperCase().toString()}"]`,
        ).style.display = 'block';
      } else if (e.key === 'Space' || e.keyCode === 32) {
        this.lettersArray.push(e.key);
      } else if (e.key === 'Backspace') {
        if (this.lettersArray.length > 0) {
          if (
            this.lettersArray.count(
              this.lettersArray[this.lettersArray.length - 1],
            ) <= 1
          ) {
            if (this.lettersArray[this.lettersArray.length - 1] !== ' ') {
              this.$el.querySelector(
                `[data-name="${
                  this.lettersArray[this.lettersArray.length - 1]
                }"]`,
              ).style.display = 'none';
            }
          }
          this.lettersArray.pop();
        }
      } else if (e.key === 'Escape') {
        this.hideLetters();
        this.lettersArray = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  padding-top: var(--spacer-l-m);
  @include mq(s) {
    padding-top: var(--spacer-xl);
  }
  color: var(--gray);

  user-select: none;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;

  * {
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
    -webkit-user-select: none;
  }
}

.pointer {
  cursor: pointer;
}
svg {
  display: block;
  max-width: 100%;
  max-height: calc(100vh - 32px);
  margin: 0 auto;
}

.desktop {
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  z-index: -1;
}

path {
  display: none;
  fill: white;
}

.clear {
  position: fixed;
  left: var(--spacer-xxxs);
  right: var(--spacer-xxxs);
  bottom: var(--spacer-l-m);
  @include mq(s) {
    bottom: var(--spacer-s);
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
  }
}

@include mq($until: s) {
  svg {
    max-height: calc(100 * var(--vh, 1vh) - 8px);
  }
}
</style>
