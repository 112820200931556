<template>
  <div
    :class="[
      'hero',
      titleTheme,
      data.acf.synopsis.length > 0 ? 'hero--synopsis' : 'hero--initial',
    ]"
  >
    <Flex
      dir="column"
      justify="center"
      align="center"
      class="hero__content t-center"
      :class="[video ? 'hero--video' : false]"
    >
      <Figure
        v-if="!video"
        :data="{
          value: {
            image: data.acf.image,
            image_mobile: data.acf.image_mobile,
          },
          settings: {
            theme: 'hero',
            mobile: {
              toggle: data.acf.image_mobile,
              theme: 'hero',
            },
          },
        }"
      />
      <Link
        v-if="data.acf.rsvp && data.acf.availability"
        class="rsvp"
        :data="{
          title: 'RSVP',
          url: {
            path: '/rsvp',
            query: {
              event_id: data.id,
            },
          },
        }"
        theme="button"
      />
      <div
        v-else-if="data.acf.rsvp && !data.acf.availability"
        class="rsvp btn-container t-center"
      >
        <div class="rsvp--content">
          RSVP Closed
        </div>
      </div>
    </Flex>

    <div
      :class="`${titleTheme}--info t-center ${
        data.acf.color ? data.acf.color : ''
      }`"
    >
      <Title
        v-if="data.acf.project.name || data.acf.type.name"
        :value="
          data.acf.project.name ? data.acf.project.name : data.acf.type.name
        "
        typo="small-thumb"
        class="hero--project"
      />
      <Title
        v-if="data.acf.artist"
        :value="data.acf.artist"
        typo="big"
        class="t-uppercase"
      />
      <Title
        v-if="data.acf.title"
        :value="data.acf.title"
        typo="big"
        class="t-uppercase"
      />
      <Title
        :value="parseDate(data.acf.from, data.acf.until)"
        typo="big"
        class="t-uppercase"
      />
    </div>
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import Figure from '@/components/media/figure';
import Link from '@/components/typo/link';
import { parseDate } from '@/assets/js/utils';

export default {
  name: 'Hero',
  components: {
    Title,
    Figure,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    video: {
      type: Boolean,
      default: false,
    },
    deadline: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      parseDate,
      formEnabled: false,
    };
  },
  computed: {
    titleTheme() {
      return (this.data.acf.project.name === 'Afterimage'
        && this.deadline
        && this.deadline.length > 0)
        || this.data.acf.title_position === 'bottom'
        ? 'hero--bottom'
        : 'hero--default';
    },
  },
  methods: {
    openForm(e) {
      e.preventDefault();
      this.formEnabled = true;
    },
    closeForm() {
      this.formEnabled = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  position: relative;
  overflow: hidden;
  color: var(--gray);
  z-index: 2;

  &__content {
    position: relative;
    height: calc(100 * var(--vh, 1vh));
  }

  &--initial {
    padding-bottom: var(--spacer-xs);
  }

  &--video {
    pointer-events: none;

    * {
      pointer-events: auto;
    }
  }

  &--bottom {
    &--info {
      padding: var(--spacer-xs) 0 0;
    }
  }

  &--default {
    &--info {
      position: absolute;
      top: 50vh;
      left: 50%;
      transform: translate(-50%, calc(-50% - var(--spacer-xs)));
      @include mq(s) {
        transform: translate(-50%, calc(-50% - var(--spacer-xs-m)));
      }
      width: 100%;
      z-index: 3;
    }
  }
  &--project {
    @include mq(s) {
      padding-bottom: var(--spacer-xxxs);
    }
  }

  .white {
    color: var(--white);
  }

  .black {
    color: var(--black);
  }

  .rsvp {
    position: absolute;
    left: var(--spacer-xxxs);
    right: var(--spacer-xxxs);
    bottom: var(--spacer-xxxs);
    z-index: 10;
    @include mq(s) {
      left: initial;
      right: initial;
      bottom: var(--spacer-s);
    }
    &--content{
      color: white;
      padding: var(--spacer-xxs) var(--spacer-btn-m) var(--spacer-xs);
      width: 100%;
    }
  }
}
</style>
