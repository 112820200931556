<template>
  <component
    :is="component"
    :slug="slug"
    :type="type"
  />
</template>

<script>
import Page from '@/views/Page';
import Agenda from '@/views/Agenda';

export default {
  name: 'SwitchComponent',
  components: {
    Page,
    Agenda,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      component:
        (this.$route.name === 'Agenda'
          && this.$store.state.route.from
          && this.$store.state.route.from.name === 'Agenda')
        || this.$route.meta.component === 'agenda'
          ? Agenda
          : Page,
    };
  },
};
</script>

<style></style>
