<template>
  <Wrapper
    v-if="events.length > 0"
    wrapper
    :size="{default: 's', s: 'xs'}"
  >
    <Form
      enable-form
      select
      :data="{ options: events }"
    />
  </Wrapper>
</template>

<script>
import Form from '@/components/typo/form';

export default {
  name: 'RSVP',
  components: {
    Form,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      events: [],
    };
  },
  async created() {
    const { data } = await this.$store.dispatch('getRsvp');
    this.events = Object.entries(data).map((item) => ({
      id: item[0],
      value: item[1],
    }));
  },
};
</script>

<style></style>
