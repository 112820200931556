<template>
  <section class="password">
    <Wrapper
      boxed
      :size="{ default: 's', s: 'xs' }"
    >
      <div class="btn-container">
        <Input
          ref="password"
          v-model="password"
          :data="{
            fn: submitPassword,
          }"
          placeholder="Password"
          name="password"
          type="password"
          theme="password"
        />
      </div>
      <transition name="fade">
        <p
          v-if="wrong"
          class="wrong"
        >
          Wrong credentials.
        </p>
      </transition>
    </Wrapper>
  </section>
</template>

<script>
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
import Input from '@/components/typo/input';

export default {
  name: 'Password',
  components: {
    Input,
  },
  data() {
    return {
      password: null,
      wrong: false,
      wrongTimeout: null,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  methods: {
    submitPassword() {
      if (this.password === this.options.extra.password.value) {
        this.$store.commit('UNLOCK_DOWNLOADS');
        Cookies.set('sm_downloads', '1', { expires: 30 });
      } else {
        if (this.wrongTimeout) window.clearTimeout(this.wrongTimeout);
        this.wrong = true;

        this.wrongTimeout = setTimeout(() => {
          this.wrong = false;
        }, 1400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  flex: 1 0 100%;
  display: flex;
  align-items: center;

  & > * {
    position: relative;
  }

  .wrong {
    position: absolute;
    padding-top: var(--spacer-xs);
    width: 100%;
    text-align: center;
    color: var(--gray);
  }
}
</style>
