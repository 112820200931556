var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.hideHeader && !_vm.hideHeader && _vm.$route.name),expression:"!$route.meta.hideHeader && !hideHeader && $route.name"}],ref:"header",class:_vm.isSocialMenuActive ? 'header header--no-special-padding' : 'header'},[_c('Flex',{ref:"header",attrs:{"justify":_vm.isSocialMenuActive ? 'space-between' : '',"row-gap":"xs","column-gap":"xs"}},[_c('Flex',{staticClass:"menu-wrapper",attrs:{"column-gap":"xs"}},[(_vm.$route.name === 'Single')?_c('Link',{attrs:{"data":{
          fn: _vm.$parent.goBack,
          title: 'Back',
        },"theme":"button"}}):_vm._e(),_c('Menu',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.$route.name === 'Single' && _vm.$mq.isTablet)),expression:"!($route.name === 'Single' && $mq.isTablet)"}]}),(_vm.$route.name === 'Agenda')?_c('Link',{directives:[{name:"show",rawName:"v-show",value:((!_vm.searchBar && _vm.$mq.isTablet) || !_vm.$mq.isTablet),expression:"(!searchBar && $mq.isTablet) || !$mq.isTablet"}],staticClass:"filter-btn",attrs:{"data":{
          fn: _vm.toggleSearchBar,
          title: _vm.filterLabel,
        },"is-active":_vm.isFilterActive,"theme":"button"}}):_vm._e(),(_vm.$route.name === 'Agenda' && !_vm.$mq.isTablet)?_c('div',{staticClass:"slide btn-container search"},[_c('Input',{ref:"input",attrs:{"data":{
            title: 'titolo',
            fn: (e) => {
              if (_vm.$refs.searchBar) {
                _vm.$refs.searchBar.setSearch(e);
              }
            },
          },"theme":"search","placeholder":"Search","type":"text","name":"s"}})],1):_vm._e(),(_vm.$route.name === 'Agenda' && !_vm.$mq.isTablet && _vm.$refs.searchBar)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$refs.searchBar.requestEmpty),expression:"!$refs.searchBar.requestEmpty"}],staticClass:"btn-container clear"},[_c('Link',{attrs:{"data":{
            fn: _vm.$refs.searchBar.clearRequest,
            title: 'Clear',
          },"theme":"button","has-container":false}})],1):_vm._e()],1),_c('Menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSocialMenuActive),expression:"isSocialMenuActive"}],attrs:{"name":"social"}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.$route.name === 'Agenda')?_c('SearchBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchBar),expression:"searchBar"}],ref:"searchBar",attrs:{"close-search-bar":_vm.closeSearchBar}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }