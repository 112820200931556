<template>
  <div
    :class="`video-player video-player-embed video-player--${
      data.settings.width ? data.settings.width : 'default'
    }`"
    :style="`--aspect-ratio-w: ${ratio[0]}; --aspect-ratio-h: ${ratio[1]}; --width: ${originalWidth}px; --height: ${originalHeight}px; --ratio: ${ratio[0]/ratio[1]}`"
  >
    <div
      v-if="data.settings.source === 'url'"
      ref="player"
      :class="`plyr plyr--url plyr--${
        data.settings.width ? data.settings.width : 'default'
      }`"
      v-html="data.value.url"
    />

    <video
      v-if="data.settings.source === 'file'"
      ref="player"
      :class="`plyr plyr--self plyr--${
        data.settings.width ? data.settings.width : 'default'
      }`"
      :data-poster="
        data.settings.poster && data.value.poster
          ? data.value.poster.sizes.l
          : null
      "
      controls
    >
      <source
        :src="data.value.src.url"
        type="video/mp4"
      >
    </video>

    <Richtext
      v-if="data.value.caption"
      :data="{ innerHTML: data.value.caption }"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Richtext from '@/components/typo/richtext';

export default {
  name: 'VideoPlyr',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
      ratio: [],
      originalWidth: 0,
      originalHeight: 0,
    };
  },
  computed: {
    embed() {
      return false;
    },
  },
  mounted() {
    this.plyr = new Plyr(this.$refs.player, {
      autoplay: false,
      controls: ['play', 'progress', 'current-time', 'duration', 'mute'],
      // enabled: !this.$mq.isSmartphone,
      fullscreen: false,
      storage: {
        enabled: false,
      },
    });

    this.plyr.on('ready', () => {
      if (this.plyr.provider === 'vimeo') {
        Promise.all([
          this.plyr.embed.getVideoWidth(),
          this.plyr.embed.getVideoHeight(),
        ])
          .catch(() => {
            this.plyr.elements.container.classList.add('error');
          })
          .then((dimensions) => {
            this.plyr.elements.container.classList.add('ready');
            [this.originalWidth, this.originalHeight] = dimensions;
            this.ratio = this.calculateRatio(this.originalWidth, this.originalHeight);
            this.$nextTick(() => {
              if (this.plyr.elements.wrapper.clientWidth < 500) {
                this.plyr.elements.controls.classList.add('plyr--custom');
              }
            });
          });
      } else {
        this.plyr.elements.container.classList.add('ready');
      }
    });
    this.$bus.$on('windowResized', this.calculateAspectRatioInPx);
  },
  destroyed() {
    this.$bus.$off('windowResized', this.calculateAspectRatioInPx);
  },
  methods: {
    calculateRatio(x, y) {
      let n;
      for (n = y; n > 1; n -= 1) {
        if (x % n === 0 && y % n === 0) {
          x /= n;
          y /= n;
        }
      }
      return [x, y];
    },
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  max-width: 100%;
  @include aspect-ratio(var(--aspect-ratio-w), var(--aspect-ratio-h));
  &--default {
    @include mq(s) {
      height: 100%;
      max-height: 80vh;
      // @supports not (aspect-ratio: var(--aspect-ratio-w)/var(--aspect-ratio-h)) {
      //   height: calc(var(--height) * var(--ratio));
      // }
    }
    @supports not (aspect-ratio: var(--aspect-ratio-w)/var(--aspect-ratio-h)) {
        width: calc(var(--width) * var(--ratio));
    }
    width: auto;
    max-width: 100%;
    .plyr--default {
      height: 100%;
    }
    overflow: hidden;
  }
  // &--full-bleed {
  //   .plyr--url {
  //     @include aspect-ratio(var(--aspect-ratio-w), var(--aspect-ratio-h));
  //   }
  // }
}
</style>
