<template>
  <div class="files">
    <Grid class="files--header">
      <Column :size="{ default: '8', m: '5', l: '5' }">
        Name
      </Column>
      <Column
        :size="{ default: '2', l: '3' }"
        class="u-hidden--until-m"
      >
        Format
      </Column>
      <Column
        :size="{ default: '2', l: '3' }"
        class="u-hidden--until-m"
      >
        Upload
      </Column>
      <Column :size="{ default: '4', m: '2', l: '1' }" />
    </Grid>
    <Grid
      v-for="item in data.value.files"
      :key="item.file.id"
      class="files--item"
    >
      <Column :size="{ default: '8', m: '5', l: '5' }">
        {{ item.file.title
        }}<span class="u-hidden--from-m">. {{ item.file.subtype }}</span>
      </Column>
      <Column
        :size="{ default: '2', l: '3' }"
        class="u-hidden--until-m"
      >
        .{{ item.file.subtype }}
      </Column>
      <Column
        :size="{ default: '3', l: '3' }"
        class="u-hidden--until-m"
      >
        <span v-html="formatDate(item.file.date)" />
      </Column>
      <Column :size="{ default: '4', m: '2', l: '1' }">
        <div class="t-right">
          <a :href="item.file.url">Download</a>
        </div>
      </Column>
    </Grid>
  </div>
</template>

<script>
export default {
  name: 'Files',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      const dateParsed = Date.parse(date.replace(/-/g, '/'));
      const dateObj = new Date(dateParsed);
      return dateObj.toLocaleDateString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.files {
  color: var(--gray);
  &--header {
    line-height: 2em;
    border-bottom: 1px solid var(--gray);
  }
  &--item {
    text-transform: uppercase;
    line-height: 2em;
    border-bottom: 1px solid var(--gray);
  }
}
</style>
