<template>
  <div class="embed">
    <iframe
      :src="data.value.url"
      frameborder="0"
    />
  </div>
</template>

<script>
export default {
  name: 'Embed',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    googleMapsURLToEmbedURL(GoogleMapsURL) {
      // eslint-disable-next-line
      const coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(GoogleMapsURL);
      if (coords != null) {
        const coordsArray = coords[1].split(',');
        return `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d${coordsArray[1]}!3d${coordsArray[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098`;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.embed {
  filter: grayscale(1) invert(1);
  iframe {
    display: block;
    width: 100%;
    height: 60vw;
    @include mq(s) {
      height: 60vh;
    }
  }
}
</style>
