var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`video-player video-player-embed video-player--${
    _vm.data.settings.width ? _vm.data.settings.width : 'default'
  }`,style:(`--aspect-ratio-w: ${_vm.ratio[0]}; --aspect-ratio-h: ${_vm.ratio[1]}; --width: ${_vm.originalWidth}px; --height: ${_vm.originalHeight}px; --ratio: ${_vm.ratio[0]/_vm.ratio[1]}`)},[(_vm.data.settings.source === 'url')?_c('div',{ref:"player",class:`plyr plyr--url plyr--${
      _vm.data.settings.width ? _vm.data.settings.width : 'default'
    }`,domProps:{"innerHTML":_vm._s(_vm.data.value.url)}}):_vm._e(),(_vm.data.settings.source === 'file')?_c('video',{ref:"player",class:`plyr plyr--self plyr--${
      _vm.data.settings.width ? _vm.data.settings.width : 'default'
    }`,attrs:{"data-poster":_vm.data.settings.poster && _vm.data.value.poster
        ? _vm.data.value.poster.sizes.l
        : null,"controls":""}},[_c('source',{attrs:{"src":_vm.data.value.src.url,"type":"video/mp4"}})]):_vm._e(),(_vm.data.value.caption)?_c('Richtext',{attrs:{"data":{ innerHTML: _vm.data.value.caption }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }