<template>
  <div class="index-component">
    <div
      ref="scroller"
      class="homepage"
    >
      <div class="item t-uppercase t-center">
        <div class="item__text u-hidden--from-s">
          <div class="item__text--content">
            <Title
              v-if="home.text"
              :value="home.text"
              typo="big"
            />
          </div>
        </div>

        <div
          v-if="home.symbol"
          ref="icon"
          class="item__icon item__icon--logo"
          :style="`--maskUrl: url(${home.symbol}); --maskClip: none; --maskColor: white; --maskBackground: transparent`"
        >
          <div
            v-show="home.symbol"
            class="item__icon--content"
          />
        </div>
      </div>
      <router-link
        v-for="(item, index) in data"
        :key="index"
        :to="$relativeUrl(item.link)"
        class="item--link"
      >
        <div
          ref="item"
          class="item t-center"
        >
          <Video
            v-if="item.acf.teaser_video"
            ref="teaser"
            :data="{
              value: {
                vimeo: item.acf.teaser_video,
              },
              settings: {
                theme: 'hero',
                teaser: true,
              },
            }"
            class="item__image"
          />

          <Figure
            v-else-if="item.acf.image"
            :data="{
              value: {
                image: item.acf.image,
              },
              settings: {
                theme: 'hero',
              },
            }"
            :lazyload="false"
            class="item__image"
          />

          <div class="item__text">
            <div
              ref="paragraph"
              class="item__text--content"
            >
              <Title
                v-if="item.acf.project.name || item.acf.type.name"
                :value="
                  item.acf.project.name
                    ? item.acf.project.name
                    : item.acf.type.name
                "
                typo="small-thumb"
              />
              <Title
                v-if="item.acf.artist"
                :value="item.acf.artist"
                typo="big"
                class="t-uppercase"
              />
              <Title
                v-if="item.acf.title"
                :value="item.acf.title"
                typo="big"
                class="t-uppercase"
              />
              <Title
                :value="parseDate(item.acf.from, item.acf.until)"
                typo="big"
                class="t-uppercase"
              />
            </div>
          </div>
          <div
            v-show="item.acf.symbol"
            ref="icon"
            class="item__icon"
            :style="`--maskUrl: url(${item.acf.symbol});
          ${
              item.acf.project.slug === 'afterimage'
                ? '--maskColor: black; --maskBackground: black;'
                : ''
            }
          ${
              !item.acf.project.slug && item.acf.type.slug === 'exhibition'
                ? '--maskClip: none; --maskColor: black; --maskBackground: transparent'
                : ''
            }
          ${
              item.acf.project.slug === 'artifact'
                ? '--maskClip: none; --maskColor: white; --maskBackground: transparent;'
                : ''
            }
          ${
              item.acf.project.slug === 'billboard' ||
              (!item.acf.project.slug && (item.acf.type.slug === 'digital' || item.acf.type.slug === 'event'))
                ? '--maskColor: white; --maskBackground: white;'
                : ''
            }
          `"
          >
            <div
              v-show="item.acf.symbol"
              class="item__icon--content"
            />
          </div>
        </div>
      </router-link>
      <AppFooter class="homepage--footer" />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';

import AppFooter from '@/components/ui/footer';
import Title from '@/components/typo/title';
import Figure from '@/components/media/figure';
import Video from '@/components/media/video';
import { parseDate } from '@/assets/js/utils';

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({
  // default is "resize,visibilitychange,DOMContentLoaded,load" so we can remove "resize" from the list:
  autoRefreshEvents: 'visibilitychange, DOMContentLoaded, load',
});

export default {
  name: 'HomeScroll',
  components: {
    Title,
    Figure,
    Video,
    AppFooter,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      parseDate,
      root: null,
      start: false,
    };
  },
  computed: {
    ...mapGetters(['options', 'overlay']),
    home() {
      return this.options.home;
    },
    isMobile() {
      return this.$mq.isMobile;
    },
  },
  watch: {
    isMobile: {
      handler(val, old) {
        if (old && !val) {
          this.refresh();
        }
      },
    },
    overlay(val) {
      if (!val && !this.start) {
        this.start = true;
        this.refresh();
      }
      if (val) {
        if (this.$refs.teaser && this.$refs.teaser.length > 0) {
          this.$refs.teaser.forEach((video) => {
            video.$refs.player.pause();
          });
        }
      } else if (this.$refs.teaser && this.$refs.teaser.length > 0) {
        this.$refs.teaser.forEach((video) => {
          video.$refs.player.play();
        });
      }
    },
  },
  mounted() {
    if (this.$route.name === 'Index' && !this.start) {
      this.start = true;
    }
    this.init();
    this.$bus.$on('windowResized', this.setTitleHeight);
  },
  destroyed() {
    this.$bus.$off('windowResized', this.setTitleHeight);
    this.destroy();
  },
  methods: {
    async refresh() {
      await this.$refs.scroller.scrollTo(0, 0);
      // await this.setTitleHeight();
      await ScrollTrigger.refresh();
    },
    init() {
      this.setTitleHeight();
      this.$refs.item.forEach((item, i) => {
        gsap.from(this.$refs.icon[i], {
          clipPath: 'inset(100% 0px 0px 0px)',
          ease: 'none',
          scrollTrigger: {
            scroller: '.homepage',
            trigger: item,
            start: 'top bottom',
            end: 'bottom bottom',
            scrub: true,
            group: 'homepage-triggered',
          },
        });
      });
    },
    destroy() {
      this.getGroup('homepage-triggered').forEach((t) => {
        t.kill();
      });
    },
    setTitleHeight() {
      this.$refs.paragraph.forEach((paragraph) => {
        paragraph.style.setProperty(
          '--homeTitleHeight',
          `${paragraph.clientHeight / 2}px`,
        );
      });
    },
    getGroup(group) {
      return ScrollTrigger.getAll().filter((t) => t.vars.group === group);
    },
    fillPath(data) {
      const paths = data.querySelectorAll('path');
      paths.forEach((path) => {
        path.style.fill = '';
        path.removeAttribute('fill');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.index-component {
  position: relative;
}

.homepage {
  @include mq(s) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100 * var(--vh, 1vh));
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .item {
    height: calc(100 * var(--vh, 1vh));
    overflow: hidden;
    position: relative;
    background: var(--black);
    display: flex;
    flex-direction: column-reverse;
    @include mq(s) {
      overflow: initial;
      display: block;
      position: sticky;
      top: 0;
      padding: 0;
    }
    &__image {
      position: absolute;
      z-index: 0;
      top: 0;
      height: 100%;
      @include mq(s) {
        position: static;
        display: block;
      }
    }
    &__text {
      color: var(--gray);
      @include mq($until: s) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 2;
        padding: var(--spacer-xxxs);
      }
      @include mq(s) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 4;
      }
      &--content {
        @include mq(s) {
          padding: var(--spacer-s);
          position: sticky;
          top: calc(50% - var(--homeTitleHeight) - var(--spacer-xs-m));
        }
      }
    }
    &__icon {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border: var(--spacer-xxxs) solid var(--maskBackground);
      margin-bottom: 0;
      will-change: clip-path;
      transform: translate3d(0, 0, 0);
      width: 100%;
      height: calc(100 * var(--vh, 1vh));
      @include mq($until: s) {
        clip-path: inset(0% 0px 0px) !important;
      }
      @include mq(s) {
        position: fixed;
        border: var(--spacer-s) solid var(--maskBackground);
        height: calc(100 * var(--vh, 1vh));
      }
      &--content {
        height: 100%;
        width: 100%;
        background: var(--maskColor);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-origin: content-box;
        mask-origin: content-box;
        -webkit-mask-image: var(--maskUrl, ""),
          var(--maskClip, linear-gradient(#fff, #fff));
        mask-image: var(--maskUrl, ""),
          var(--maskClip, linear-gradient(#fff, #fff));
        -webkit-mask-composite: var(--maskClip, destination-out);
        mask-composite: var(--maskClip, exclude);
      }
    }
    &--link {
      display: block;
      @include mq(s) {
        position: sticky;
        top: 0;
        width: 100%;
      }
    }
  }
  &--footer {
    position: absolute;
    bottom: 0;
    padding-top: var(--spacer-xxxs);
    z-index: 5;
    @include mq(s) {
      position: sticky;
      top: 0;
      bottom: initial;
    }
  }
}
</style>
