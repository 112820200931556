<template>
  <div id="app">
    <AppHeader />
    <keep-alive>
      <transition
        :css="false"
        appear
        mode="in-out"
        @enter="enter"
        @leave="leave"
      >
        <router-view
          :key="filteredPath.path"
          ref="base"
          :class="[
            'main-base',
            overlay ? 'main-base--overlay' : false,
            filteredPath.name === 'Agenda' ? 'main-base--agenda' : false,
            filteredPath.params && filteredPath.params.pathMatch
              ? `main-base--${filteredPath.params.pathMatch}`
              : false,
          ]"
          :style="{
            display: !baseVisibility ? 'none' : 'flex',
          }"
        />
      </transition>
    </keep-alive>

    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enterOverlay"
      @leave="leaveOverlay"
    >
      <router-view
        :key="$route.path"
        name="overlay"
        class="main-overlay"
      />
    </transition>

    <div
      ref="blur"
      class="blur"
      :style="{
        display: !baseVisibility ? 'none' : 'block',
      }"
    />

    <Link
      v-if="overlay"
      ref="back"
      :data="{
        fn: goBack,
        title: 'Back',
      }"
      theme="button"
      class="back"
      :style="{
        display: !baseVisibility ? 'none' : 'block',
      }"
    />

    <div
      ref="disableScroll"
      class="disable-scroll"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';
import { checkInstagramHeight } from '@/assets/js/utils';

import AppHeader from '@/components/ui/header';
import Link from '@/components/typo/link';

gsap.registerPlugin(CustomEase);
CustomEase.create('custom', '0.77, 0, 0.175, 1');

export default {
  name: 'App',
  components: {
    AppHeader,
    Link,
  },
  mixins: [lazyload],
  data() {
    return {
      prevScroll: 0,
      blur: 6,
      baseVisibility: true,
      prevRoute: null,
      filteredPath: null,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'overlay', 'transition']),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.setFilteredPath();
      },
    },
    overlay: {
      immediate: true,
      handler(val) {
        document.body.classList[val ? 'add' : 'remove']('is-overlay');

        this.$nextTick(() => {
          if (this.$refs.back) {
            gsap.set(this.$refs.back.$el, {
              autoAlpha: 0,
            });
          }
        });

        const { pageYOffset } = window;
        if (val) {
          this.prevScroll = pageYOffset;

          document.documentElement.style.setProperty(
            '--scroll',
            `${this.prevScroll}px`,
          );
        }
      },
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    if (this.$mq.isSafari) {
      this.$el.classList.add('is-safari');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());

        this.$mq.vhResized();

        if (!this.$mq.isSmartphone) {
          this.$mq.vh();
        }
      }, 400),
    );

    window.addEventListener('orientationchange', () => {
      window.requestAnimationFrame(() => {
        checkInstagramHeight();
        this.$mq.vh();
      });
    });

    this.$aion.add(this.checkVisibileBase, 'checkVisibileBase');
  },
  methods: {
    setFilteredPath() {
      let route = this.$route;
      if (this.$route.name === 'Single') {
        if (
          this.$store.state.route.from
          && this.$store.state.route.from.name
          && this.$store.state.route.from.name !== 'Single'
        ) {
          route = this.$store.state.route.from;
          this.prevRoute = route;
        } else if (this.prevRoute) {
          route = this.prevRoute;
        } else {
          route = {
            name: 'Index',
            path: '/',
          };
        }
      }
      this.filteredPath = route;
    },
    enter(el, done) {
      if (
        this.$store.state.route.from
        && this.$store.state.route.from.name === 'Single'
      ) {
        this.$store.commit('SET_TRANSITION', false);
        done();
      } else {
        disableBodyScroll(this.$refs.disableScroll);

        gsap.set(el, {
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: 1,
          y: `${window.innerHeight}`,
        });

        this.$nextTick(() => {
          const tl = gsap.timeline({
            defaults: { duration: 0.4, ease: 'custom' },
          });

          tl.to(el, {
            y: '0px',
            clearProps: true,
          });

          tl.then(() => {
            clearAllBodyScrollLocks();
            this.$store.commit('SET_TRANSITION', false);
            window.scroll(0, 0);
            done();
          });
        });
      }
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
    enterOverlay(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      this.$store.commit('SET_OVERLAY', true);

      disableBodyScroll(this.$refs.disableScroll);

      this.$nextTick(() => {
        gsap.set(el, {
          y: `${window.innerHeight}`,
        });

        const tl = gsap.timeline({
          defaults: { duration: 0.4, ease: 'custom' },
        });

        tl.to(el, {
          y: '0px',
          clearProps: true,
        });

        tl.then(() => {
          clearAllBodyScrollLocks();
          this.$store.commit('SET_TRANSITION', false);

          this.baseVisibility = false;

          if (this.$refs.back) {
            gsap.set(this.$refs.back.$el, {
              autoAlpha: 1,
            });
          }

          gsap.set(this.$refs.blur, {
            '--blur': `${this.blur}px`,
            autoAlpha: 1,
          });

          done();
        });
      });
    },
    leaveOverlay(el, done) {
      this.$store.commit('SET_TRANSITION', true);

      disableBodyScroll(this.$refs.disableScroll);

      const scroll = window.pageYOffset;
      const isBackVisible = scroll >= document.body.clientHeight - window.innerHeight;

      gsap.set(document.body, {
        overflow: 'hidden',
        height: window.innerHeight,
      });

      gsap.set(el, {
        overflow: 'auto',
        height: window.innerHeight,
        position: 'absolute',
      });

      gsap.set(el.children[0], {
        y: scroll * -1,
      });

      if (isBackVisible) {
        gsap.to(this.$refs.back.$el, {
          autoAlpha: 0,
          duration: 0.2,
          ease: 'custom',
        });
      } else {
        gsap.set(this.$refs.back.$el, {
          autoAlpha: 0,
        });
      }

      const tl = gsap.timeline({
        defaults: { duration: 0.4, ease: 'custom' },
      });

      tl.fromTo(
        el,
        {
          y: '0px',
        },
        {
          y: `${window.innerHeight * -1}px`,
        },
        0,
      ).to(
        this.$refs.blur,
        {
          '--blur': '0px',
          autoAlpha: 0,
        },
        0,
      );

      tl.then(() => {
        done();
        this.$store.commit('SET_OVERLAY', false);

        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('height');

        this.$nextTick(() => {
          window.scroll(0, this.prevScroll);
          clearAllBodyScrollLocks();
        });
      });
    },
    goBack() {
      this.$router.push(this.filteredPath);
    },
    checkVisibileBase() {
      if (this.overlay && !this.transition) {
        const backHeight = parseInt(
          getComputedStyle(document.documentElement).getPropertyValue(
            '--back-height',
          ),
          10,
        );

        this.baseVisibility = window.pageYOffset
          >= document.body.clientHeight - window.innerHeight - backHeight;
      } else if (!this.baseVisibility) {
        this.baseVisibility = true;
      }
    },
  },
};

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.app-loaded {
  #loader {
    display: none;
  }
}

.main-base {
  min-height: calc(100 * var(--vh, 1vh));
  position: relative;
  background: var(--black);
  z-index: 0;
  padding-bottom: env(safe-area-inset-bottom);

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    transform: translate3d(0, calc(-1 * var(--scroll, 0)), 0px);
    pointer-events: none;

    backface-visibility: hidden;
  }

  display: flex;
  flex-direction: column;

  .gutenberg {
    flex: 1;
    align-content: flex-start;
  }

  footer {
    color: var(--gray);
  }

  &--agenda {
    background: var(--gray);
    min-height: calc(100 * var(--vh-resized, 1vh));

    footer {
      color: var(--white);
      a:hover {
        color: var(--black);
      }
    }
  }
}

.main-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  pointer-events: none;

  backface-visibility: hidden;

  .container {
    position: relative;
    pointer-events: auto;
    background: var(--white);
    z-index: 4;
  }

  &.main--afterimage {
    .container {
      background: var(--black);
    }
  }

  &.main--exhibition {
    .container {
      background: var(--black);
    }
  }

  &::after {
    content: "";
    display: block;
    position: relative;
    height: var(--back-height);
    pointer-events: none;
  }
}

.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(var(--blur, 0px));
  opacity: 0;
  background: var(--bg-black);
  pointer-events: none;
}

.back {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  bottom: 80px;
  @include mq(s) {
    bottom: 200px;
  }
}
</style>
