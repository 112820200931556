var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'select-wrapper',
    _vm.computedModel !== _vm.options[0].id.toString()
      ? 'select-wrapper-active'
      : false,
    _vm.theme ? `select--${_vm.theme}` : 'select--default',
  ]},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedModel),expression:"computedModel"}],attrs:{"id":_vm.id,"name":_vm.id,"required":_vm.required},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.computedModel=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.options),function(option){return _c('option',{key:option.id,attrs:{"disabled":option.disabled},domProps:{"value":option.id.toString()}},[_vm._v(" "+_vm._s(option.value)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }