<template>
  <router-link
    :to="$relativeUrl(data.link)"
    class="thumb"
  >
    <Grid
      row-gap="0"
      align="start"
    >
      <Column :size="{ default: 2, s: 1 }">
        <Title
          v-if="year"
          typo="small"
          :value="year"
        />
      </Column>
      <Column
        :size="{ default: 1 }"
        class="u-hidden--until-s"
      >
        <Title
          v-if="from"
          typo="small"
          :value="from"
        />
      </Column>
      <Column
        :size="{ default: 1 }"
        class="u-hidden--until-s"
      >
        <Title
          v-if="until"
          typo="small"
          :value="until"
        />
      </Column>
      <Column
        :size="{ default: 7, s: 4 }"
        class="thumb--artist"
      >
        <Title
          v-if="data.acf.artist || data.acf.title"
          typo="small"
          class="t-ellipsis"
          :value="data.acf.artist ? data.acf.artist : data.acf.title"
        />
      </Column>
      <Column
        :size="{ default: 7, s: 4 }"
        class="thumb--title"
      >
        <Title
          v-if="data.acf.title && data.acf.artist"
          typo="small"
          class="t-ellipsis"
          :value="data.acf.title"
        />
      </Column>
      <Column
        :size="{ default: 3, s: 1 }"
        class="thumb--project"
      >
        <Title
          v-if="data.acf.project.name || data.acf.type.name"
          :value="data.acf.project.name ? data.acf.project.name : data.acf.type.name"
          typo="small"
        />
      </Column>
    </Grid>
  </router-link>
</template>

<script>
import Title from '@/components/typo/title';
import { getYearAndDate } from '@/assets/js/utils';

export default {
  name: 'ThumbSmall',
  components: {
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formattedDate: getYearAndDate(this.data.acf.from, this.data.acf.until),
    };
  },
  computed: {
    year() {
      return this.formattedDate.year;
    },
    from() {
      return this.formattedDate.from;
    },
    until() {
      return this.formattedDate.until;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;
  text-decoration: none;
  @include mq($until: m) {
    &--project {
      grid-row: 1;
      grid-column-start: 10;
    }
    &--title {
      grid-column-start: 3;
    }
  }
}
</style>
