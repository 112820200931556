export const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => window.matchMedia(query).matches;
  if (
    'ontouchstart' in window
    /* eslint-disable-next-line no-mixed-operators, no-undef */
    || (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    '',
  );
  return mq(query);
};

export const flatAndSort = (object, key) => {
  const keys = Object.keys(object);
  const sortedKeys = keys.sort((a, b) => object[a][key] - object[b][key]);
  const sortedObject = [];
  sortedKeys.forEach((k) => sortedObject.push({ key: k, ...object[k] }));
  return sortedObject;
};

export const getBreakpoint = () => JSON.parse(
  window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--breakpoints'),
);

export const is = (breakpoint) => {
  const b = window.innerWidth;
  const c = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(`--breakpoints-${breakpoint}`),
    10,
  );
  if (c) {
    return b < c;
  }
  return false;
};

export const isSafari = () => typeof navigator !== 'undefined'
  && navigator.vendor
  && navigator.vendor.includes('Apple')
  && navigator.userAgent
  && !navigator.userAgent.includes('CriOS')
  && !navigator.userAgent.includes('Chrome')
  && !navigator.userAgent.includes('FxiOS');

export const isIOS = () => [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform)
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const isEdge = () => typeof window !== 'undefined' && window.navigator.userAgent.includes('Edge');

export const isIE = () => typeof window !== 'undefined'
  && (window.navigator.userAgent.includes('MSIE')
    || window.navigator.userAgent.includes('Trident/'));

export const isInstagram = () => navigator.userAgent.match(/instagram/i);

export const norm = (val, max, min) => (val - min) / (max - min);

export const viewport = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  ratio: window.innerWidth / window.innerHeight,
});

export const isSmartphone = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);

export const lerp = (a, b, n) => (1 - n) * a + n * b;

export const modulate = (value, rangeA, rangeB, limit) => {
  if (limit == null) {
    limit = false;
  } // eslint-disable-line
  const [fromLow, fromHigh] = Array.from(rangeA);
  const [toLow, toHigh] = Array.from(rangeB);
  const result = toLow + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow);

  if (limit === true) {
    if (toLow < toHigh) {
      if (result < toLow) {
        return toLow;
      }
      if (result > toHigh) {
        return toHigh;
      }
    } else {
      if (result > toLow) {
        return toLow;
      }
      if (result < toHigh) {
        return toHigh;
      }
    }
  }

  return result;
};

export const loadScript = (src) => new Promise((resolve) => {
  const s = document.createElement('script');
  let r = false;
  s.type = 'text/javascript';
  s.src = src;
  // eslint-disable-next-line
    s.onload = s.onreadystatechange = function () {
    if (!r && (!this.readyState || this.readyState === 'complete')) {
      r = true;
      resolve();
    }
  };
  const t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
});

export const getParm = (url, base) => {
  const re = new RegExp(`(\\?|&)${base}\\=([^&]*)(&|$)`);
  const matches = url.match(re);
  if (matches) {
    return matches[2];
  }
  return '';
};

export const parseVideoURL = (url) => {
  const retVal = {};
  const matches = url.match(/vimeo.com\/(\d+)/);

  if (url.indexOf('youtube.com/watch') !== -1) {
    retVal.provider = 'youtube';
    retVal.id = getParm(url, 'v');
  } else if (matches) {
    retVal.provider = 'vimeo';
    // eslint-disable-next-line prefer-destructuring
    retVal.id = matches[1];
  }

  return retVal;
};

export const parseBlockName = (blockName) => {
  const array = blockName.split('/');
  return array.length > 1 ? array[1] : array[0];
};

export const parseDate = (from, until) => {
  const fromValue = from.replace(/-/g, '/');
  const untilValue = until.replace(/-/g, '/');

  // if (isSafari() || isIOS()) {
  //   fromValue = from.replace(/-/g, '/');
  //   untilValue = until.replace(/-/g, '/');
  // }

  const f = new Date(fromValue);
  const u = new Date(untilValue);

  // check if until date is valid
  if (
    !(u instanceof Date && !Number.isNaN(u.valueOf()))
    || f.toISOString() === u.toISOString()
  ) {
    return `${`0${f.getDate()}`.slice(-2)}.${`0${f.getMonth() + 1}`.slice(
      -2,
    )}.${f.getFullYear()}`;
  }
  if (f.getFullYear() < u.getFullYear()) {
    return `${`0${f.getDate()}`.slice(-2)}.${`0${f.getMonth() + 1}`.slice(
      -2,
    )}.${f.getFullYear()}–${`0${u.getDate()}`.slice(-2)}.${`0${
      u.getMonth() + 1
    }`.slice(-2)}.${u.getFullYear()}`;
  }

  return `${`0${f.getDate()}`.slice(-2)}.${`0${f.getMonth() + 1}`.slice(
    -2,
  )}–${`0${u.getDate()}`.slice(-2)}.${`0${u.getMonth() + 1}`.slice(
    -2,
  )}.${u.getFullYear()}`;
};

export const getYearAndDate = (fromValue, untilValue) => {
  const f = new Date(fromValue.replace(/-/g, '/'));
  const u = new Date(untilValue.replace(/-/g, '/'));

  const year = f.getFullYear().toString();
  const from = `${`0${f.getDate()}`.slice(-2)}.${`0${f.getMonth() + 1}`.slice(
    -2,
  )}`;
  const until = `${`0${u.getDate()}`.slice(-2)}.${`0${u.getMonth() + 1}`.slice(
    -2,
  )}`;

  return { year, from, until };
};

export const timer = {
  endtime: null,
  timeinterval: null,
  text: '',
  seconds: '',

  getTimeRemaining: () => {
    const total = Date.parse(timer.endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  },

  updateClock: () => {
    const t = timer.getTimeRemaining(timer.endtime);
    timer.text = `${t.days} d ${t.hours} h ${t.minutes} m ${t.seconds} s`;
    timer.seconds = t.seconds;
    timer.days = t.days;
    timer.total = t.total;

    if (t.total <= 0) {
      clearInterval(timer.timeinterval);
    }
  },

  clear: () => clearInterval(timer.timeinterval),

  init: (endtime) => {
    timer.endtime = endtime;
    timer.timeinterval = setInterval(timer.updateClock, 1000);
  },
};

export const fillPath = (data) => {
  const paths = data.querySelectorAll('path');
  paths.forEach((path) => {
    path.style.fill = '';
    path.removeAttribute('fill');
  });
};

export const checkFlexGap = () => {
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1;
  flex.parentNode.removeChild(flex);

  if (!isSupported) {
    document.documentElement.classList.add('no-flexbox-gap');
  }
};

export const checkInstagramHeight = () => {
  if (isInstagram()) {
    const heightBlock = document.createElement('div');
    heightBlock.style.position = 'fixed';
    heightBlock.style.top = 0;
    heightBlock.style.right = 0;
    heightBlock.style.left = 0;
    heightBlock.style.height = '-webkit-fill-available';

    document.body.appendChild(heightBlock);

    const vh = heightBlock.clientHeight;
    document.documentElement.style.setProperty('--instagram-vh', vh);

    heightBlock.parentNode.removeChild(heightBlock);
  }
};
