<template>
  <div
    ref="gallery"
    class="gallery swiper-container"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(item, index) in data.value.gallery"
        :key="index"
        class="item swiper-slide"
      >
        <Figure
          ref="item"
          :data="{
            value: {
              image: item,
            },
            settings: {},
          }"
          :lazyload="false"
          disable-ratio
          theme="gallery"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

import Figure from '@/components/media/figure';

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.data.value.gallery.length > 0) {
        this.gallery = new Swiper(
          this.$refs.gallery,
          Object.assign(this.settings, this.extraSettings),
        );
      }
    });
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
};
</script>

<style lang="scss">
.gallery {
  overflow: hidden;

  .swiper-slide {
    width: auto;
    height: auto;
  }
}
</style>
