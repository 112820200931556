export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  SET_OVERLAY(state, value) {
    state.overlay = value;
  },
  HIDE_HEADER(state, value) {
    state.hideHeader = value;
  },
  SET_SEARCH(state, value) {
    state.search = value;
  },
  UNLOCK_DOWNLOADS(state) {
    state.lockDownloads = true;
  },
};
