var render = function render(){var _vm=this,_c=_vm._self._c;return _c('figure',{class:[
    'figure',
    _vm.themeClass,
    _vm.disableRatio ? 'figure--native' : false,
    _vm.centeredClass ? 'figure--centered' : false,
  ],style:({
    '--image-w': _vm.image.width,
    '--image-h': _vm.image.height,
  }),attrs:{"data-lazy":_vm.lazyload ? 'false' : null}},[_c('div',{class:['image--wrapper']},[_c('img',{ref:"image",class:['image'],attrs:{"data-src":_vm.lazyload ? _vm.src : null,"src":!_vm.lazyload ? _vm.src || _vm.image.sizes.l : '',"alt":_vm.image.alt}}),(_vm.lazyload)?_c('div',{staticClass:"placeholder"}):_vm._e()]),(_vm.caption)?_c('figcaption',[_c('Richtext',{attrs:{"data":{ innerHTML: _vm.caption }}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }