var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"container"},[_c('keep-alive',[(!_vm.search)?_c('Archive',{attrs:{"data":{
          value: {
            title: 'current',
            post_type: 'post',
          },
          settings: {
            filters: ['agenda:current'],
            posts_per_page: 24,
            order: true,
          },
        }}}):_vm._e()],1),_c('keep-alive',[(!_vm.search)?_c('Archive',{attrs:{"data":{
          value: {
            title: 'upcoming',
            post_type: 'post',
          },
          settings: {
            filters: ['agenda:upcoming'],
            posts_per_page: 24,
            order: true,
          },
        }}}):_vm._e()],1),(!_vm.search)?_c('Archive',{attrs:{"data":{
        value: {
          title: 'past',
          post_type: 'post',
        },
        settings: {
          filters: ['agenda:past'],
          posts_per_page: 24,
          infinite_scroll: true,
          order: true,
        },
      },"thumb":!_vm.$mq.isMobile ? undefined : 'small'}}):_vm._e(),(_vm.search)?_c('Archive',{key:_vm.prevRequest,attrs:{"data":{
        value: {
          post_type: 'post',
          search: _vm.search.query.search,
        },
        settings: {
          filters: _vm.filters && _vm.filters.length > 0 ? _vm.filters : null,
          posts_per_page: 12,
          infinite_scroll: true,
          order: true,
        },
      },"thumb":"figure"}}):_vm._e(),_c('Spacer',{attrs:{"bottom":{default: 'l-m', s: 'xl'}}})],1),_c('AppFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }