<template>
  <div :class="`form form--${themeClass} ${isEnabled}`">
    <form
      v-show="
        ((!success && data.available && !select) || (!success && select)) &&
          !error
      "
      @submit.prevent="sendForm"
    >
      <Select
        v-if="select"
        v-show="!success"
        id="event_id"
        ref="select"
        theme="form"
        required
        :model.sync="event_id"
        :options="[
          { id: '', value: `Select`, disabled: true },
          ...data.options,
        ]"
      />
      <Input
        v-show="enableForm"
        ref="firstname"
        v-model="first_name"
        :data="{
          type: 'text',
        }"
        :theme="themeClass !== 'hero' ? 'form' : 'hero'"
        placeholder="Name"
        name="first_name"
        required
      />
      <Input
        v-show="enableForm"
        ref="lastname"
        v-model="last_name"
        :data="{
          type: 'text',
        }"
        :theme="themeClass !== 'hero' ? 'form' : 'hero'"
        placeholder="Surname"
        name="last_name"
        required
      />
      <Input
        v-show="enableForm"
        ref="email"
        v-model="email"
        :data="{
          type: 'email',
        }"
        :theme="themeClass !== 'hero' ? 'form' : 'hero'"
        placeholder="Email"
        name="email"
        required
      />
      <Link
        v-show="enableForm"
        :data="{
          fn: () => {},
          title: 'Submit',
          type: 'submit',
        }"
        theme="button"
        :class="`form--button ${enableForm ? 'form--enabled' : ''}`"
        :has-container="false"
        :additional-themes="['link--button-form']"
      />
      <Link
        v-show="!enableForm && !success"
        :data="{
          fn: openForm,
          title: 'RSVP',
        }"
        theme="button"
      />
    </form>

    <!-- on success or not available-->
    <div
      v-show="success || error || (!data.available && !select)"
      class="form-success t-center"
    >
      <div class="btn-container form-success__container">
        <div class="form-success__container--content">
          {{ success ? "RSVP Confirmed" : error ? error : "RSVP Closed" }}
        </div>
      </div>
      <Link
        v-show="success && theme !== 'hero'"
        :data="{
          fn: refreshForm,
          title: 'Refresh',
        }"
        theme="button"
        class="form--refresh"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/typo/input';
import Link from '@/components/typo/link';
import Select from '@/components/typo/select';

export default {
  name: 'Form',
  components: {
    Select,
    Input,
    Link,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    enableForm: {
      type: Boolean,
      default: false,
    },
    openForm: {
      type: Function,
      default: () => {},
    },
    select: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      event_id: '',
      first_name: '',
      last_name: '',
      success: false,
      error: false,
    };
  },
  computed: {
    isEnabled() {
      return this.enableForm && !this.success && !this.error
        ? 'btn-container'
        : '';
    },
    themeClass() {
      return this.theme ? this.theme : 'default';
    },
  },
  created() {
    if (this.$route.query.event_id) {
      this.event_id = this.$route.query.event_id;
    }
  },
  methods: {
    sendForm() {
      let valid = this.$refs.email.$el.querySelector('input').checkValidity()
        && this.$refs.firstname.$el.querySelector('input').checkValidity()
        && this.$refs.lastname.$el.querySelector('input').checkValidity();

      if (this.select) {
        valid = valid
          && this.$refs.select.$el.querySelector('select').checkValidity()
          && this.event_id !== ''
          && this.first_name !== ''
          && this.last_name !== '';
      }

      if (valid) {
        const request = {
          fn: 'rsvp',
          params: {
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            event_id: this.select ? parseInt(this.event_id, 10) : this.data.id,
          },
        };

        this.$store.dispatch('sendRequest', request).then((res) => {
          if (res.data.response) {
            this.success = true;
          } else {
            this.error = res.data.message;
          }
        });
      }
    },
    refreshForm() {
      this.success = false;
      this.email = '';
      this.event_id = '';
      this.first_name = '';
      this.last_name = '';
      this.$refs.email.$el.querySelector('input').value = '';
      this.$refs.firstname.$el.querySelector('input').value = '';
      this.$refs.lastname.$el.querySelector('input').value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacer-xxxs);
  html.no-flexbox-gap & {
    & > * {
      margin-bottom: var(--spacer-xxxs);
    }
  }
}
.form {
  text-transform: initial;
  text-align: left;
  &--default {
    background: none;
    .form--input {
      @include mq(s) {
        background: var(--bg-white);
        padding: 3px;
        border-radius: var(--border-radius);
      }
    }
    .form--button {
      width: auto;
      padding: 3px;
    }
    .form--refresh {
      margin: auto;
    }
  }

  &--enabled {
    background: var(--bg-white);
    border-radius: var(--border-radius);
  }

  .form-success {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-xxxs);
    &__container {
      width: auto;
      margin: auto;
      text-align: center;
      color: var(--white);
      &--content {
        padding: var(--spacer-xxs) var(--spacer-btn-m) var(--spacer-xs);
      }
    }
  }
}
</style>

<!--
<Flex
  class="form--input"
  :dir="{ default: themeClass !== 'hero' ? 'column' : 'row', s: 'row' }"
  row-gap="xxxs"
  nowrap
>
  <Input
    v-show="enableForm"
    ref="email"
    v-model="email"
    :data="{
      type: 'email',
    }"
    :theme="themeClass !== 'hero' ? 'form' : 'hero'"
    placeholder="Your e-mail"
    name="email"
    required
  />
  <Link
    v-show="enableForm"
    :data="{
      fn: () => {},
      title: 'Submit',
      type: 'submit',
    }"
    theme="button"
    :class="`form--button ${enableForm ? 'form--enabled' : ''}`"
    :has-container="false"
    />
</Flex>
-->
