<template>
  <div
    v-if="countdown && countdown.text"
    class="countdown btn-container"
    :class="[isVisible ? 'countdown--visible' : false]"
  >
    <div class="countdown--content">
      <span class="countdown--pre">Available for</span> {{ countdown.text }}
    </div>
    <div class="countdown--circle">
      <div
        ref="pie"
        class="pie"
        :style="`--p: ${(countdown.days / 30) * 100}`"
      />
    </div>
  </div>
</template>

<script>
import { timer } from '@/assets/js/utils';

export default {
  name: 'Countdown',
  props: {
    deadline: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timer,
      countdown: null,
      isVisible: true,
    };
  },
  mounted() {
    this.countdown = timer;
    this.countdown.init(this.deadline);

    this.$aion.add(this.checkCountdownScroll, 'checkCountdownScroll', true);
  },
  beforeDestroy() {
    if (this.countdown) {
      this.countdown.clear();
    }
    this.$aion.remove('checkCountdownScroll');
  },
  methods: {
    checkCountdownScroll() {
      this.isVisible = window.pageYOffset === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  @extend %typo--button;
  position: fixed;
  top: var(--spacer-xxxs);
  right: var(--spacer-xxxs);
  column-gap: 0;
  &--pre {
    display: none;
  }
  @include mq(m) {
    top: var(--spacer-s);
    right: var(--spacer-s);
    &--pre {
      display: inline-block;
    }
  }

  z-index: 6;
  color: var(--white);

  &--content {
    width: 0;
    height: 0;
    overflow: hidden;
  }
  &:hover,
  &--visible {
    .countdown--circle {
      display: none;
    }
    .countdown--content {
      height: auto;
      width: 200px;
      @include mq(m) {
        width: 290px;
      }
      text-align: center;
      padding: var(--spacer-xxs) var(--spacer-btn-m) var(--spacer-xs);
    }
  }

  $d: 34px;

  &--circle {
    --d: #{$d};
    width: var(--d);
    & .pie {
      position: relative;
      width: 0;
      --slist: var(--bg-white) calc(var(--p) * 1%), transparent 0;
      background: linear-gradient(90deg, var(--slist));
      transition: --p 0.5s;
      @supports (background: conic-gradient(red, tan)) {
        padding: 50%;
        border-radius: 50%;
        --a: calc(0.5 * var(--p) / 100 * 1turn - 90deg);
        --pos: rotate(var(--a)) translate(calc(0.25 * var(--d)))
          rotate(calc(-1 * var(--a)));
        background: conic-gradient(var(--slist));
      }
    }
  }
}
</style>
