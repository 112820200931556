import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Link from '@/components/typo/link';
import Figure from '@/components/media/figure';
import Gallery from '@/components/media/gallery';
import Archive from '@/components/blocks/archive';
import Posts from '@/components/blocks/posts';
import RSVP from '@/components/blocks/rsvp';
import Files from '@/components/blocks/files';
import VideoPlyr from '@/components/media/video-plyr';
import Embed from '@/components/media/embed';

const components = new Map();

components.set('title', Title);
components.set('paragraph', Richtext);
components.set('link', Link);
components.set('image', Figure);
components.set('gallery', Gallery);
components.set('archive', Archive);
components.set('post', Posts);
components.set('rsvp', RSVP);
components.set('files', Files);
components.set('video', VideoPlyr);
components.set('embed', Embed);

export default components;
