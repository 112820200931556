<template>
  <div
    :class="['richtext']"
    v-html="value || data.innerHTML"
  />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (window.twttr && this.mergeProps.value.includes('twitter-tweet')) {
      // eslint-disable-next-line no-undef
      window.twttr.widgets.load();
    }

    // // eslint-disable-next-line no-undef
    // FB.init({
    //   xfbml: true,
    //   version: 'v10.0',
    // });
  },
};
</script>

<style lang="scss">
.richtext {
  @extend %typo--p;
  @extend %typo--mobile-h2;
  color: var(--gray-text);
  .t-indent {
    padding-left: var(--spacer-l-m);
    &--first-line {
      text-indent: var(--spacer-l-m);
    }
    @include mq(s) {
      padding-left: var(--spacer-xxl);
      &--first-line {
        text-indent: var(--spacer-xxl);
      }
    }
  }

  .t-big {
    @extend %typo--rare;
  }

  .t-dialogue {
    float: left;
    width: var(--spacer-l-m);
    @include mq(s) {
      width: var(--spacer-xxl);
    }
    padding-right: var(--spacer-xs);
    overflow: hidden;
    text-indent: initial;
  }

  .t-dialogue::after {
    clear: both;
  }

  strong {
    font-weight: normal;
  }

  a {
    text-decoration: underline;
    transition: color 0.2s ease-out;
  }

  .main-base & {
    a:hover {
      color: var(--white);
    }
  }
  .main-overlay & {
    a:hover {
      color: var(--black);
    }
  }
  .main--afterimage & {
    a:hover {
      color: var(--white);
    }
  }
  .main--exhibition & {
    a:hover {
      color: var(--white);
    }
  }
}
</style>
