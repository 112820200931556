<template>
  <Flex
    class="search-bar"
    gap
  >
    <div class="slider">
      <div
        v-if="$mq.isTablet"
        class="slide btn-container search"
      >
        <Input
          ref="input"
          :data="{
            title: 'titolo',
            fn: setSearch,
          }"
          theme="search"
          placeholder="Search"
          type="text"
          name="s"
          :keyup="{
            fn: setRequestAndClose,
          }"
        />
      </div>
      <div class="slide btn-container dates">
        <Link
          :is-active="request.date === 'current'"
          :data="{
            fn: () => setDate('current'),
            title: 'Current',
          }"
          theme="button"
          :has-container="false"
        />
        <Link
          :is-active="request.date === 'upcoming'"
          :data="{
            fn: () => setDate('upcoming'),
            title: 'Upcoming',
          }"
          theme="button"
          :has-container="false"
        />
        <!-- <Link
          :is-active="request.date === 'past'"
          :data="{
            fn: () => setDate('past'),
            title: 'Past',
          }"
          theme="button"
          :has-container="false"
        /> -->
        <Select
          id="year"
          :model.sync="request.year"
          :options="[{ id: 'all', value: 'Past' }, ...years()]"
          :label="'Past'"
        />
      </div>

      <div class="slide btn-container types">
        <Link
          v-for="type in types"
          :key="type.id"
          :is-active="request.type === type.slug"
          :data="{
            fn: () => setType(type.slug),
            title: type.name,
          }"
          theme="button"
          :has-container="false"
        />
      </div>

      <div class="slide btn-container projects">
        <Link
          v-for="project in projects"
          :key="project.id"
          :is-active="request.project === project.slug"
          :data="{
            fn: () => setProject(project.slug),
            title: project.name,
          }"
          theme="button"
          :has-container="false"
        />
      </div>

      <div
        v-show="!requestEmpty"
        class="slide btn-container clear--mobile"
      >
        <Link
          :data="{
            fn: clearRequest,
            title: 'Clear',
          }"
          theme="button"
        />
      </div>
    </div>
    <Link
      :data="{
        fn: setRequestAndClose,
        // title: `${requestEmpty ? 'Close' : 'Confirm'}`,
        title: 'Close',
      }"
      theme="button"
      :has-container="true"
      class="confirm"
    />
  </Flex>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';

import Link from '@/components/typo/link';
import Input from '@/components/typo/input';
import Select from '@/components/typo/select';

export default {
  name: 'SearchBar',
  components: {
    Link,
    Input,
    Select,
  },
  props: {
    closeSearchBar: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      slider: null,
      requestEmpty: true,
      request: {
        date: null,
        project: null,
        type: null,
        year: 'all',
        search: '',
      },
    };
  },
  computed: {
    ...mapGetters(['types', 'projects']),
  },
  watch: {
    request: {
      handler() {
        this.requestEmpty = !(
          this.request.search !== ''
          || this.request.date
          || this.request.project
          || this.request.type
          || this.request.year !== 'all'
        );
        this.setRequest();
      },
      deep: true,
    },
    $route(val, old) {
      if (
        old.name === 'Agenda'
        && Object.keys(old.query).length > 0
        && val.name === 'Agenda'
        && Object.keys(val.query).length === 0
      ) {
        this.clearRequest();
      }
    },
  },
  mounted() {
    const { query } = this.$route;
    if (query) {
      if (query.date) this.setDate(query.date);
      if (query.project) this.setProject(query.project, 10);
      if (query.type) this.setType(query.type, 10);
      if (query.search) {
        this.request.search = query.search;
        this.search = query.search;

        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.$el.querySelector('input').value = this.search;
          }

          if (this.$parent.$refs.input) {
            this.$parent.$refs.input.$el.querySelector('input').value = this.search;
          }
        });
      }
      if (query.year) {
        this.request.year = query.year;
      }
    }
  },
  methods: {
    setRequest() {
      if (
        this.request.search !== ''
        || this.request.date
        || this.request.project
        || this.request.type
        || this.request.year !== 'all'
      ) {
        this.requestEmpty = false;

        const query = Object.keys(this.request)
          .filter(
            (key) => this.request[key]
              && this.request[key] !== ''
              && this.request[key] !== 'all',
          )
          .reduce(
            (obj, key) => ({
              ...obj,
              [key]: this.request[key].toString(),
            }),
            {},
          );
        const queryIsDifferent = JSON.stringify(query) !== JSON.stringify(this.$route.query);

        if (Object.keys(this.$route.query).length === 0 || queryIsDifferent) {
          this.$router.replace({
            query,
          });
        }
        this.$store.commit('SET_SEARCH', { query });
      } else {
        this.requestEmpty = true;
        this.$store.commit('SET_SEARCH', null);

        if (Object.keys(this.$route.query).length > 0) {
          this.$router.replace({
            query: null,
          });
        }
      }
    },
    setDate(value) {
      if (value === this.request.date) {
        this.request.date = null;
      } else {
        this.request.date = value;
      }

      if (value === 'past') {
        if (!this.request.date) {
          this.request.year = 'all';
        }
      }
    },
    setProject(value) {
      if (value === this.request.project) {
        this.request.project = null;
      } else {
        this.request.project = value;
      }
    },
    setType(value) {
      if (value === this.request.type) {
        this.request.type = null;
      } else {
        this.request.type = value;
      }
    },
    // eslint-disable-next-line
    setSearch: debounce(function (e) {
      this.request.search = e.target.value;
    }, 500),

    clearRequest() {
      this.request = {
        date: null,
        project: null,
        type: null,
        search: '',
        year: 'all',
      };

      if (this.$refs.input) {
        this.$refs.input.$el.querySelector('input').value = '';
      }

      if (this.$parent.$refs.input) {
        this.$parent.$refs.input.$el.querySelector('input').value = '';
      }
    },
    years() {
      const years = [];
      const to = new Date().getFullYear();
      const from = 2017;

      for (let index = 0; index <= to - from; index += 1) {
        years.push({ id: to - index, value: to - index });
      }
      return years;
    },
    setRequestAndClose() {
      this.closeSearchBar();
      // this.setRequest();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  padding-top: var(--spacer-xs);

  html.no-flexbox-gap & {
    padding-top: 0;
  }

  flex: 1 0 0px;
  pointer-events: auto;
  overflow-x: hidden;

  .confirm {
    display: none;
  }
  .clear {
    &--mobile {
      display: none;
    }
  }

  .slider {
    flex: 1 0 0px;
    display: flex;

    @include mq(t) {
      overscroll-behavior-x: contain;
      overflow-x: auto;
      border-radius: var(--border-radius) 0px 0px var(--border-radius);

      &::after {
        content: "";
        display: block;
        border: 1px solid transparent;
      }
    }
  }
}

/* Mobile css for search-bar component */
@include mq($until: t) {
  .search-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100 * var(--vh-resized, 1vh));
    background: var(--bg-black);
    backdrop-filter: blur($blur);
    padding-top: 0;
    padding-left: var(--spacer-xxxs);
    padding-right: var(--spacer-xxxs);

    .confirm {
      position: fixed;
      left: var(--spacer-xxxs);
      right: var(--spacer-xxxs);
      bottom: calc(var(--spacer-xxxs) + env(safe-area-inset-bottom));
      display: block;
      background: var(--bg-gray);
      border-radius: var(--border-radius);
      z-index: 10;
    }

    .clear {
      display: none;
      &--mobile {
        display: block;
      }
    }
    .slider {
      min-height: calc(100 * var(--vh, 1vh));
      flex-direction: column;
      box-sizing: border-box;
      overflow-y: auto;
      padding: var(--spacer-xxs) 0 var(--spacer-xl);
    }

    .slide {
      flex-direction: column;
      width: 100%;
      padding: 0;
      align-items: center;
      background: none;
      backdrop-filter: none;
      height: auto;
      & > * {
        width: 100%;
        text-align: center;
        padding: 3px;
        background: var(--bg-gray);
        border-radius: var(--border-radius);
      }

      ::v-deep select {
        text-align: center;
      }

      @include mq($until: t) {
        row-gap: var(--row-gap-xxxs);

        html.no-flexbox-gap & {
          & > * {
            margin-bottom: var(--row-gap-xxxs);
          }
        }
      }

      @include mq(t) {
        column-gap: var(--column-gap-xxxs);

        html.no-flexbox-gap & {
          & > * + * {
            margin-left: var(--column-gap-xxxs);
          }
        }
      }
    }
  }
}

.slider {
  @include mq($until: t) {
    row-gap: var(--row-gap-xs);

    html.no-flexbox-gap & {
      & > * {
        margin-bottom: var(--row-gap-xs);
      }
    }
  }

  @include mq(t) {
    column-gap: var(--column-gap-xs);

    html.no-flexbox-gap & {
      & > * + * {
        margin-left: var(--column-gap-xs);
      }
    }
  }
}

@include mq($until: t) {
  .confirm {
    html.no-flexbox-gap & {
      margin: 0 !important;
    }
  }
}
</style>
