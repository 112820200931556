var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'hero',
    _vm.titleTheme,
    _vm.data.acf.synopsis.length > 0 ? 'hero--synopsis' : 'hero--initial',
  ]},[_c('Flex',{staticClass:"hero__content t-center",class:[_vm.video ? 'hero--video' : false],attrs:{"dir":"column","justify":"center","align":"center"}},[(!_vm.video)?_c('Figure',{attrs:{"data":{
        value: {
          image: _vm.data.acf.image,
          image_mobile: _vm.data.acf.image_mobile,
        },
        settings: {
          theme: 'hero',
          mobile: {
            toggle: _vm.data.acf.image_mobile,
            theme: 'hero',
          },
        },
      }}}):_vm._e(),(_vm.data.acf.rsvp && _vm.data.acf.availability)?_c('Link',{staticClass:"rsvp",attrs:{"data":{
        title: 'RSVP',
        url: {
          path: '/rsvp',
          query: {
            event_id: _vm.data.id,
          },
        },
      },"theme":"button"}}):(_vm.data.acf.rsvp && !_vm.data.acf.availability)?_c('div',{staticClass:"rsvp btn-container t-center"},[_c('div',{staticClass:"rsvp--content"},[_vm._v(" RSVP Closed ")])]):_vm._e()],1),_c('div',{class:`${_vm.titleTheme}--info t-center ${
      _vm.data.acf.color ? _vm.data.acf.color : ''
    }`},[(_vm.data.acf.project.name || _vm.data.acf.type.name)?_c('Title',{staticClass:"hero--project",attrs:{"value":_vm.data.acf.project.name ? _vm.data.acf.project.name : _vm.data.acf.type.name,"typo":"small-thumb"}}):_vm._e(),(_vm.data.acf.artist)?_c('Title',{staticClass:"t-uppercase",attrs:{"value":_vm.data.acf.artist,"typo":"big"}}):_vm._e(),(_vm.data.acf.title)?_c('Title',{staticClass:"t-uppercase",attrs:{"value":_vm.data.acf.title,"typo":"big"}}):_vm._e(),_c('Title',{staticClass:"t-uppercase",attrs:{"value":_vm.parseDate(_vm.data.acf.from, _vm.data.acf.until),"typo":"big"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }