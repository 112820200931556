export default {
  props: {
    preview: {
      type: Object,
      default: () => {},
    },
    slug: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      post: null,
    };
  },
  created() {
    this.setData(this.$route);
  },
  methods: {
    setData(to) {
      if (this.preview) {
        this.post = this.preview;
        return;
      }

      const request = {
        type: null,
        slug: null,
      };
      request.type = this.type || to.meta.type || 'pages';

      if (this.slug) {
        request.slug = this.slug;
      } else if (to.params.slug) {
        request.slug = to.params.slug;
      } else if (to.meta.slug) {
        request.slug = to.meta.slug;
      } else if (to.params.pathMatch) {
        request.slug = to.params.pathMatch;
      }
      this.post = this.$store.state.wp[request.type].default[
        encodeURI(request.slug).toLowerCase()
      ];
      this.$store.commit('SET_CURRENT_POST', this.post);
    },
  },
};
