<template>
  <div :class="containerClass">
    <compoment
      :is="tag"
      :class="[
        'link',
        themeClass,
        [...additionalThemes],
        data.size ? `typo--${data.size}` : 'typo--button',
        disabled ? 'link--disabled' : null,
      ]"
      :href="external ? url : undefined"
      :to="!external ? url : undefined"
      :target="link.target"
      :data-active="isActive"
      :type="data.type ? data.type : ''"
      @[event]="data.fn"
      v-html="title"
    />
  </div>
</template>

<script>
export default {
  name: 'Link',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasContainer: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    additionalThemes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    event() {
      return this.data.fn ? 'click' : null;
    },
    tag() {
      return this.data.fn ? 'button' : this.external ? 'a' : 'router-link';
    },
    link() {
      return this.data.value ? this.data.value.link : this.data;
    },
    title() {
      return this.link.title;
    },
    url() {
      if (this.link.url && typeof this.link.url === 'string') {
        return this.$relativeUrl(this.link.url);
      }
      return this.link.url;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    themeClass() {
      return this.theme
        ? `link--${this.theme}`
        : this.settings.theme
          ? `link--${this.settings.theme}`
          : false;
    },
    containerClass() {
      return this.hasContainer
        && ((this.data.settings && this.data.settings.theme === 'button')
          || this.theme === 'button')
        ? 'btn-container'
        : this.data.fn
          ? 'link--block'
          : 'link--wrapper';
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: inline-block;
  // &.router-link-active {
  //   // style
  // }
}

button {
  cursor: pointer;
}

.link--disabled {
  opacity: 0.5;
  pointer-events: none;

  * {
    pointer-events: none !important;
  }
}

.link {
  appearance: none;
  text-decoration: none;
  &--button {
    position: relative;
    border: none;
    color: var(--white);
    padding: var(--spacer-xxs) var(--spacer-btn-m) var(--spacer-xs);
    text-align: center;
    border-radius: var(--border-radius) var(--border-radius);
    background: transparent;
    pointer-events: all;
    white-space: nowrap;
    width: 100%;

    &[data-active],
    &.router-link-exact-active,
    &.router-link-active {
      background: var(--bg-white);
    }

    @include mq($from: s) {
      &:hover {
        background: var(--bg-white);
      }
    }
    &-menu {
      @include mq($until: t) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &-form{
      background: var(--bg-white);
    }
  }
  &--wrapper {
    display: inline-block;
  }
  &--block {
    display: block;
  }
}

.link-home {
  .link.router-link-active:not(.router-link-exact-active):not(:hover) {
    background: transparent;
    &:hover{
      background: var(--bg-white)
    }
  }
}
</style>
