<template>
  <main>
    <Password v-if="$route.meta.password && !lockDownloads" />
    <template v-else>
      <Gutenberg
        :data="post.gds_blocks"
        :is-index="isIndex"
      />
      <AppFooter v-if="post.slug !== 'home'" />
    </template>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import data from '@/mixins/data';

import AppFooter from '@/components/ui/footer';
import Gutenberg from '@/components/blocks';
import Password from '@/components/ui/password';

/* eslint-disable no-undef */
const { page_on_front } = __VUE_WORDPRESS__.routing;

export default {
  name: 'Page',
  components: {
    Gutenberg,
    AppFooter,
    Password,
  },
  mixins: [data],
  computed: {
    ...mapGetters(['lockDownloads']),
    isIndex() {
      return this.post.slug === page_on_front;
    },
  },
};
</script>
