var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{class:`${
    _vm.isIndex
      ? 'gutenberg--index'
      : _vm.type === 'post'
        ? 'gutenberg--single'
        : 'gutenberg'
  }`,attrs:{"grid":"1","column-gap":"s","row-gap":"0"}},_vm._l((_vm.data),function(block,index){return _c('section',{key:index,class:[
      'section',
      `section--${_vm.parseBlockName(block.blockName)}`,
      block.attrs.bigText && block.attrs.bigText === true
        ? 'section--paragraph-bigText'
        : `section--${_vm.parseBlockName(block.blockName)}-default`,
    ]},[_c('Wrapper',{attrs:{"boxed":_vm.isBoxed(block),"size":_vm.setBlockSize(block, _vm.type)}},[_c(_vm.components.get(_vm.parseBlockName(block.blockName)),{tag:"component",attrs:{"data":block.blockName.includes('acf/') ? block.attrs.data : block}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }