/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
/* eslint-disable no-undef */
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import axios from 'axios';
import 'normalize.css';
import 'swiper/swiper-bundle.css';

import { checkFlexGap, loadScript } from '@/assets/js/utils';
import App from './App.vue';

import '@/assets/js/eventBus';
import '@/assets/js/raf';
import '@/assets/js/mq';
import '@/assets/js/layout';

checkFlexGap();

Vue.config.productionTip = false;
let gtag_id = null;

Vue.use(InlineSvgPlugin);

(async () => {
  if (typeof __VUE_WORDPRESS__ === 'undefined') {
    const axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_PROXY_TARGET}${process.env.VUE_APP_BASE_URL}wp-json/gds/`,
    });
    try {
      const { data: radl } = await axiosInstance.get('radl/');
      window.__VUE_WORDPRESS__ = radl;
    } catch (e) {
      console.error(e);
    }
  }

  const router = require('./router');
  const store = require('./store');
  sync(store.default, router.default);

  const { url: baseUrl } = __VUE_WORDPRESS__.routing;
  Vue.prototype.$relativeUrl = (url) => (url
    ? process.env.NODE_ENV === 'development'
        && process.env.VUE_APP_PROXY_TARGET
      ? url
        .replace(process.env.VUE_APP_PROXY_TARGET, '')
        .replace(baseUrl, '')
        .replace(process.env.VUE_APP_BASE_URL, '/')
      : url.replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
    : false);

  // eslint-disable-next-line no-undef
  const { options } = __VUE_WORDPRESS__.state;

  gtag_id = options.extra.gtag;

  const app = new Vue({
    router: router.default,
    store: store.default,
    render: (h) => h(App),
  });

  router.default.onReady(() => app.$mount('#app'));
})();

// eslint-disable-next-line
window._iub = [];
// eslint-disable-next-line
window._iub.csConfiguration = {
  lang: 'en-GB',
  siteId: 2535648,
  cookiePolicyId: 53291071,
  consentOnScroll: false,
  consentOnContinuedBrowsing: false,
  banner: {
    applyStyles: false,
    position: 'bottom',
    slideDown: 'true',
    content: 'We use %{cookie_policy_link}.',
    cookiePolicyLinkCaption: 'cookies',
    acceptButtonDisplay: true,
    acceptButtonCaption: 'Accept',
    rejectButtonDisplay: true,
    rejectButtonCaption: 'Reject',
    explicitWithdrawal: true,
    closeButtonDisplay: false,
  },
  callback: {
    onConsentGiven: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
        if (gtag_id) {
          window.gtag('config', gtag_id.value, {
            anonymize_ip: false,
          });
        }
      }
    },
    onConsentRejected: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        });
      }
    },
    onBannerClosed: () => {
      Vue.prototype.$bus.$emit('iubendaClose');
    },
    onBannerShown: () => {
      Vue.prototype.$bus.$emit('iubendaReady');
    },
  },
  preferenceCookie: {
    expireAfter: 180,
  },
};

loadScript('https://cdn.iubenda.com/cs/iubenda_cs.js');

document.body.addEventListener('click', (e) => {
  if (
    e
    && e.target
    && e.target.href
    && typeof e.target.href === 'string'
    && e.target.href.includes('#cookie-policy')
  ) {
    e.preventDefault();
    // eslint-disable-next-line
    window._iub.cs.api.openPreferences();
  }
});
