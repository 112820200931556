import { render, staticRenderFns } from "./SwitchComponent.vue?vue&type=template&id=b7a0ae44&"
import script from "./SwitchComponent.vue?vue&type=script&lang=js&"
export * from "./SwitchComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports