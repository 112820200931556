<template>
  <Wrapper
    tag="footer"
    boxed
  >
    <Flex
      column-gap="s"
      :class="`footer-wrapper ${$mq.isMobile ? 'btn-container' : ''}`"
    >
      <Link
        v-for="item in footer"
        :key="item.id"
        :data="{
          url: item.url,
          title: item.content,
          target: item.target,
          size: 'button',
        }"
      />
    </Flex>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';

export default {
  name: 'Footer',
  components: {
    Link,
  },
  computed: {
    ...mapGetters(['menu']),
    footer() {
      const nav = this.menu('footer');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss">
footer {
  padding-bottom: var(--spacer-l-m);
  @include mq(s){
    padding-bottom: var(--spacer-s);
  }
  .footer-wrapper {
    flex-direction: row;
    justify-content: space-between;
    @include mq(s) {
      justify-content: initial;
    }
  }

  a {
    text-decoration: none;
    transition: color 0.2s ease-out;
    padding: var(--spacer-xxs) var(--spacer-btn-m) var(--spacer-xs);
    color: var(--white);
    &:hover {
      color: var(--white);
    }
    @include mq(s){
      padding: 0;
      color: inherit;
    }
  }
}
</style>
