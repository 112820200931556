<template>
  <div
    :class="[
      'select-wrapper',
      computedModel !== options[0].id.toString()
        ? 'select-wrapper-active'
        : false,
      theme ? `select--${theme}` : 'select--default',
    ]"
  >
    <select
      :id="id"
      v-model="computedModel"
      :name="id"
      :required="required"
    >
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id.toString()"
        :disabled="option.disabled"
      >
        {{ option.value }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: () => {},
    },
    id: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  &-wrapper {
    position: relative;
    color: var(--white);
    border-radius: var(--border-radius) var(--border-radius);
    display: flex;
    @extend %typo--button;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-active,
    &:hover {
      background: var(--bg-white);
    }

    select {
      all: unset;
      cursor: pointer;
      appearance: none;
      display: block;
      margin: auto;
      box-sizing: border-box;
      text-align: center;
      padding: var(--spacer-xxs) var(--spacer-btn-m) var(--spacer-xs);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      flex: 1;

      .is-safari & {
        flex-grow: 0;
        flex-basis: auto;
        width: auto;
      }

      option {
        color: initial;
        padding: initial;
      }
    }
  }
  &--form {
    background: var(--bg-white);
    padding: 3px;
    select {
      background: var(--bg-white);
      border-radius: var(--border-radius) var(--border-radius);
    }
  }
}
</style>
