export default {
  menu: (state, getters) => (name) => state.menus[getters.lang][name],
  request:
    (state) => ({ type, params }) => {
      let res = null;
      const slugs = [];
      if (params.slug && params.slug.length > 0) {
        params.slug.forEach((slug) => {
          if (state[type][params.lang][slug]) slugs.push(slug);
        });
        return slugs.length > 0 ? slugs : null;
      }

      const request = state[type].requests.find((req) => {
        if (Object.keys(req.params).length === Object.keys(params).length) {
          res = Object.keys(req.params).every(
            (key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]),
          );
        }
        return res;
      });

      if (request) return request.data;
      return res;
    },
  totalPages:
    (state) => ({ type, params }) => {
      let res = null;
      const request = state[type].requests.find((req) => {
        if (Object.keys(req.params).length === Object.keys(params).length) {
          res = Object.keys(req.params).every(
            (key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]),
          );
        }
        return res;
      });

      if (request) return request.total;
      return res;
    },
  singleBySlug:
    (state) => ({
      type, slug, lang, context,
    }) => (state[type][lang][slug]
      && (state[type][lang][slug].context === context
        || state[type][lang][slug].context === 'view')
      ? state[type][lang][slug]
      : false),
  postType: (state) => (id) => state.postTypes[id],
  options: (state) => state.options,
  types: (state, getters) => state.event_type[getters.lang],
  projects: (state, getters) => {
    const projects = { ...state.event_project[getters.lang] };

    return Object.keys(projects)
      .filter((key) => key !== 'sm')
      .reduce((obj, key) => {
        obj[key] = projects[key];
        return obj;
      }, {});
  },
  findIdBySlug: (state, getters) => (slug, taxonomy) => state[taxonomy][getters.lang][slug].id,
};
