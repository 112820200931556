var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:`input--block ${_vm.themeClass}`,on:{"submit":function($event){$event.preventDefault();return _vm.data.fn.apply(null, arguments)}}},[_c("input",{tag:"compoment",class:[
      'input',
      _vm.data.size ? `typo--${_vm.data.size}` : null,
      _vm.disabled ? 'input--disabled' : null,
    ],attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"name":_vm.name,"value":_vm.value,"required":_vm.required},on:_vm._d({"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onKeyUp.apply(null, arguments)}},[_vm.event,_vm.data.fn])}),_c('Link',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'password'),expression:"type === 'password'"}],staticClass:"input--submit",attrs:{"data":{
      fn: () => {},
      title: 'Enter',
      type: 'submit',
    },"theme":"button","has-container":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }