<template>
  <router-link
    :to="$relativeUrl(data.link)"
    :class="`thumb t-center`"
  >
    <Figure
      v-if="data.acf.image"
      :data="{
        value: {
          image: data.acf.image,
        },
        settings: {theme: 'thumb'},
      }"
      :lazyload="false"
    />
    <Title
      v-if="data.acf.project.name || data.acf.type.name"
      :value="data.acf.project.name ? data.acf.project.name : data.acf.type.name"
      typo="small-thumb"
      class="thumb-w-figure"
    />
    <Title
      v-if="data.acf.artist"
      :value="data.acf.artist"
      typo="thumb"
      class=" t-uppercase"
    />
    <Title
      v-if="data.acf.title"
      :value="data.acf.title"
      typo="thumb"
      class=" t-uppercase"
    />
    <Title
      :value="parseDate(data.acf.from, data.acf.until)"
      typo="thumb"
      class=" t-uppercase"
    />
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import { parseDate } from '@/assets/js/utils';

export default {
  name: 'ThumbWithFigure',
  components: {
    Figure,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parseDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;
  text-decoration: none;
  &-w-figure{
    @include mq($until: s){
      padding-bottom: var(--spacer-xxxs);
    }
  }
}
</style>
