var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('AppHeader'),_c('keep-alive',[_c('transition',{attrs:{"css":false,"appear":"","mode":"in-out"},on:{"enter":_vm.enter,"leave":_vm.leave}},[_c('router-view',{key:_vm.filteredPath.path,ref:"base",class:[
          'main-base',
          _vm.overlay ? 'main-base--overlay' : false,
          _vm.filteredPath.name === 'Agenda' ? 'main-base--agenda' : false,
          _vm.filteredPath.params && _vm.filteredPath.params.pathMatch
            ? `main-base--${_vm.filteredPath.params.pathMatch}`
            : false,
        ],style:({
          display: !_vm.baseVisibility ? 'none' : 'flex',
        })})],1)],1),_c('transition',{attrs:{"css":false,"appear":"","mode":"out-in"},on:{"enter":_vm.enterOverlay,"leave":_vm.leaveOverlay}},[_c('router-view',{key:_vm.$route.path,staticClass:"main-overlay",attrs:{"name":"overlay"}})],1),_c('div',{ref:"blur",staticClass:"blur",style:({
      display: !_vm.baseVisibility ? 'none' : 'block',
    })}),(_vm.overlay)?_c('Link',{ref:"back",staticClass:"back",style:({
      display: !_vm.baseVisibility ? 'none' : 'block',
    }),attrs:{"data":{
      fn: _vm.goBack,
      title: 'Back',
    },"theme":"button"}}):_vm._e(),_c('div',{ref:"disableScroll",staticClass:"disable-scroll"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }