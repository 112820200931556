var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['archive', _vm.items.length > 0 ? 'archive-w-items' : false]},[(
      _vm.data.value.title && _vm.items.length > 0 && _vm.data.value.typo !== 'related'
    )?_c('Title',{staticClass:"t-center t-uppercase",attrs:{"value":_vm.data.value.title,"typo":_vm.data.value.typo ? _vm.data.value.typo : 'archive'}}):_vm._e(),(_vm.items.length > 0)?_c('Grid',{attrs:{"col":{
      default: _vm.thumb !== 'figure' ? 12 : 6,
      m: _vm.thumb !== 'small' ? 4 : undefined,
    },"column-gap":_vm.$mq.isMobile ? 'xxxs' : '',"row-gap":_vm.thumb !== 'small'
        ? _vm.thumb === 'default' && _vm.$mq.isMobile
          ? 'xxxs'
          : _vm.$mq.isMobile
            ? 'l'
            : 'l-t'
        : _vm.$mq.isMobile
          ? 'xxxs'
          : '0'}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c(_vm.thumbs.get(_vm.thumb),{tag:"component",attrs:{"data":item}})],1)}),0):_vm._e(),(
      _vm.items.length < _vm.total && _vm.data.settings.infinite_scroll && _vm.showLoadmore
    )?_c('Spacer',{staticClass:"load-more",attrs:{"y":{ default: 'xxxs' },"top":{ default: 'xxxs', s: 'l-t' }}},[_c('Flex',{attrs:{"justify":{ s: 'center' }}},[_c('Link',{attrs:{"data":{
          title: _vm.loadmoreLabel,
          fn: _vm.requestOffset,
        },"theme":"button"}})],1)],1):_vm._e(),(_vm.data.value.link)?_c('Link',{attrs:{"data":_vm.data.value.link}}):_vm._e(),(_vm.search && _vm.search.query && !_vm.items.length && !_vm.isSearching)?_c('div',[_c('Title',{staticClass:"t-center t-uppercase no-results",attrs:{"value":"No results"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }