<template>
  <header
    v-show="!$route.meta.hideHeader && !hideHeader && $route.name"
    ref="header"
    :class="isSocialMenuActive ? 'header header--no-special-padding' : 'header'"
  >
    <Flex
      ref="header"
      :justify="isSocialMenuActive ? 'space-between' : ''"
      row-gap="xs"
      column-gap="xs"
    >
      <Flex
        column-gap="xs"
        class="menu-wrapper"
      >
        <Link
          v-if="$route.name === 'Single'"
          :data="{
            fn: $parent.goBack,
            title: 'Back',
          }"
          theme="button"
        />
        <Menu v-show="!($route.name === 'Single' && $mq.isTablet)" />

        <Link
          v-if="$route.name === 'Agenda'"
          v-show="(!searchBar && $mq.isTablet) || !$mq.isTablet"
          :data="{
            fn: toggleSearchBar,
            title: filterLabel,
          }"
          :is-active="isFilterActive"
          theme="button"
          class="filter-btn"
        />

        <div
          v-if="$route.name === 'Agenda' && !$mq.isTablet"
          class="slide btn-container search"
        >
          <Input
            ref="input"
            :data="{
              title: 'titolo',
              fn: (e) => {
                if ($refs.searchBar) {
                  $refs.searchBar.setSearch(e);
                }
              },
            }"
            theme="search"
            placeholder="Search"
            type="text"
            name="s"
          />
        </div>

        <div
          v-if="$route.name === 'Agenda' && !$mq.isTablet && $refs.searchBar"
          v-show="!$refs.searchBar.requestEmpty"
          class="btn-container clear"
        >
          <Link
            :data="{
              fn: $refs.searchBar.clearRequest,
              title: 'Clear',
            }"
            theme="button"
            :has-container="false"
          />
        </div>
      </Flex>

      <Menu
        v-show="isSocialMenuActive"
        name="social"
      />
    </Flex>

    <transition name="fade">
      <SearchBar
        v-if="$route.name === 'Agenda'"
        v-show="searchBar"
        ref="searchBar"
        :close-search-bar="closeSearchBar"
      />
    </transition>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from '@/components/ui/menu';
import SearchBar from '@/components/ui/search-bar';
import Link from '@/components/typo/link';
import Input from '@/components/typo/input';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  name: 'Header',
  components: {
    Menu,
    SearchBar,
    Link,
    Input,
  },
  data() {
    return {
      root: null,
      searchBar: false,
      filterLabel: 'Filter',
    };
  },
  computed: {
    ...mapGetters(['hideHeader', 'search']),
    isSocialMenuActive() {
      return !(
        this.$route.name === 'Single'
        || (this.$route.name === 'Agenda' && this.$mq.isTablet)
      );
    },
    isFilterActive() {
      return (
        (this.search
          && this.search.query
          && Object.keys(this.search.query).filter((key) => key !== 'search')
            .length > 0)
        || this.searchBar
      );
    },
    isTablet() {
      return this.$mq.isTablet;
    },
  },
  watch: {
    search: {
      deep: true,
      immediate: true,
      handler(val) {
        let label = 'Filter';
        if (val && val.query) {
          let keys = Object.keys(val.query);

          if (!this.$mq.isTablet) {
            keys = keys.filter((key) => key !== 'search');
          }

          if (keys.length > 0) {
            label = `Filter <sup>${keys.length}</sup>`;
          }
        }

        this.filterLabel = label;
      },
    },
    searchBar(val) {
      if (val && !this.isTablet) {
        document.documentElement.style.setProperty('--filters', '48px');
      } else {
        document.documentElement.style.setProperty('--filters', '0px');
      }
    },
    isTablet(val) {
      if (val) {
        document.documentElement.style.setProperty('--filters', '0px');
      } else if (!val && this.searchBar) {
        document.documentElement.style.setProperty('--filters', '48px');
      }
    },
    $route(val) {
      if (val.name !== 'Agenda' && val.name !== 'Single') {
        this.searchBar = false;
      }
    },
  },
  methods: {
    closeSearchBar() {
      this.searchBar = false;
      clearAllBodyScrollLocks();
    },
    toggleSearchBar() {
      this.searchBar = !this.searchBar;
      if (this.$mq.isTablet) {
        disableBodyScroll(this.$refs.searchBar.$el);
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  color: var(--white);
  padding: var(--spacer-xxxs);
  pointer-events: none;
  @include mq(t) {
    padding: var(--spacer-s) 0 var(--spacer-s) var(--spacer-s);
  }

  .filter-btn {
    @include mq($until: t) {
      width: calc(100% - var(--spacer-xs));
      position: fixed;
      left: 50%;
      bottom: calc(var(--spacer-xxxs) + env(safe-area-inset-bottom));
      transform: translate(-50%, 0);
      z-index: 10;
    }

    .link {
      position: relative;
    }

    sup {
      @include mq(t) {
        position: absolute;
        top: 12px;
        right: 14px;
      }
    }
  }

  .menu-wrapper {
    @include mq($until: t) {
      flex: 1;
    }
  }

  &--no-special-padding {
    @include mq(t) {
      padding: var(--spacer-s);
    }
  }
}
@include mq($until: t) {
  .filter-btn {
    html.no-flexbox-gap & {
      margin: 0 !important;
    }
  }
}
</style>
