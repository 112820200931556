<template>
  <div :class="['archive', items.length > 0 ? 'archive-w-items' : false]">
    <Title
      v-if="
        data.value.title && items.length > 0 && data.value.typo !== 'related'
      "
      :value="data.value.title"
      class="t-center t-uppercase"
      :typo="data.value.typo ? data.value.typo : 'archive'"
    />
    <Grid
      v-if="items.length > 0"
      :col="{
        default: thumb !== 'figure' ? 12 : 6,
        m: thumb !== 'small' ? 4 : undefined,
      }"
      :column-gap="$mq.isMobile ? 'xxxs' : ''"
      :row-gap="
        thumb !== 'small'
          ? thumb === 'default' && $mq.isMobile
            ? 'xxxs'
            : $mq.isMobile
              ? 'l'
              : 'l-t'
          : $mq.isMobile
            ? 'xxxs'
            : '0'
      "
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <component
          :is="thumbs.get(thumb)"
          :data="item"
        />
      </div>
    </Grid>

    <Spacer
      v-if="
        items.length < total && data.settings.infinite_scroll && showLoadmore
      "
      :y="{ default: 'xxxs' }"
      :top="{ default: 'xxxs', s: 'l-t' }"
      class="load-more"
    >
      <Flex :justify="{ s: 'center' }">
        <Link
          :data="{
            title: loadmoreLabel,
            fn: requestOffset,
          }"
          theme="button"
        />
      </Flex>
    </Spacer>

    <Link
      v-if="data.value.link"
      :data="data.value.link"
    />

    <div v-if="search && search.query && !items.length && !isSearching">
      <!-- <Title
        :value="`No ${search.query.date ? search.query.date : ''} ${
          search.query.project ? search.query.project : ''
        }  ${search.query.type ? search.query.type + 's' : 'projects'}`"
        class="t-center t-uppercase"
        typo="archive"
      /> -->
      <Title
        value="No results"
        class="t-center t-uppercase no-results"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Thumb from '@/components/thumb';
import ThumbSmall from '@/components/thumb/small';
import ThumbWithFigure from '@/components/thumb/w-figure';
import Title from '@/components/typo/title';
import Link from '@/components/typo/link';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('small', ThumbSmall);
thumbs.set('figure', ThumbWithFigure);

export default {
  name: 'Archive',
  components: {
    Title,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    thumb: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      thumbs,
      request: null,
      items: [],
      offset: 0,
      total: 0,
      placeholder: 'Loading',
      isSearching: false,
      showLoadmore: true,
      loadmoreLabel: 'Load more',
      // isEnded: false,
    };
  },
  computed: {
    ...mapGetters({
      postType: 'postType',
      search: 'search',
      totalPages: 'totalPages',
    }),
  },
  mounted() {
    this.isSearching = true;
    this.setRequest();

    this.loadItems().then((items) => {
      this.total = this.totalPages(this.request);

      this.isSearching = false;
      if (items.length) {
        if (this.data.settings.infinite_scroll) {
          this.$nextTick(this.setInfiniteScroll);
        } else {
          this.$root.$children[0].lazyObserve(this.$el);
        }
      }
    });
  },
  beforeDestroy() {
    // if (
    //   medusa.ref
    //   && medusa.ref.idList.includes(`infinite-scroll-${this._uid}`)
    //   && this.data.settings.infinite_scroll
    // ) {
    //   medusa.ref.removeTarget(`infinite-scroll-${this._uid}`);
    // }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      return items;
    },
    setRequest() {
      const type = this.postType(this.data.value.post_type).rest_base;
      const per_page = parseInt(this.data.settings.posts_per_page, 10);
      const order = this.data.settings.order ? 'desc' : 'asc';
      const orderby = this.data.settings.order_by || 'acf.from';
      const { include } = this.data.value;
      const { offset } = this;

      const filters = {};
      if (this.data.settings.filters) {
        this.data.settings.filters.forEach((filter) => {
          const string = filter.split(':');
          // eslint-disable-next-line prefer-destructuring
          filters[string[0]] = string[1];
        });
      }

      this.request = {
        type,
        params: {
          ...filters,
          include,
          per_page,
          offset,
          order,
          orderby,
          lang: this.$store.state.lang,
          context: 'embed',
          _fields:
            'id, date, slug, type, link, title, acf.title, acf.from, acf.until, acf.artist, acf.symbol, acf.image, acf.project, acf.type',
          search: this.data.value.search,
        },
      };
    },
    // setInfiniteScroll() {
    //   medusa.ref.addTarget({
    //     id: `infinite-scroll-${this._uid}`,
    //     threshold: 0.0,
    //     nodes: [],
    //     mode: 'byPixel',
    //     callback: this.setOffset,
    //     autoremove: false,
    //     offsets: '0px 0px 1px 0px',
    //   });

    //   medusa.ref.pushToTarget(
    //     `infinite-scroll-${this._uid}`,
    //     this.$el.querySelector('.infinite'),
    //   );
    // },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      this.loadmoreLabel = 'Loading...';
      this.offset += parseInt(this.data.settings.posts_per_page, 10);
      this.setRequest();
      this.isSearching = true;
      this.loadItems().then(() => {
        this.loadmoreLabel = 'Load more';
        this.isSearching = false;
        this.$root.$children[0].lazyObserve(this.$el);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;
  &-w-items {
    padding-bottom: var(--spacer-xxxs);
    color: inherit;
    @include mq(s) {
      padding-bottom: var(--spacer-s);
    }
  }
}
.infinite {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 33vh;
}

.load-more {
  @include mq($until: s) {
    .flex {
      width: 100%;

      & > * {
        flex: 1;
      }
    }
  }
}

.no-results{
    padding: var(--spacer-s) 0;
    border-top: 1px solid var(--white);
}
</style>
