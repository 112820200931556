<template>
  <figure
    :class="[
      'figure',
      themeClass,
      disableRatio ? 'figure--native' : false,
      centeredClass ? 'figure--centered' : false,
    ]"
    :data-lazy="lazyload ? 'false' : null"
    :style="{
      '--image-w': image.width,
      '--image-h': image.height,
    }"
  >
    <div :class="['image--wrapper']">
      <img
        ref="image"
        :class="['image']"
        :data-src="lazyload ? src : null"
        :src="!lazyload ? src || image.sizes.l : ''"
        :alt="image.alt"
      >
      <div
        v-if="lazyload"
        class="placeholder"
      />
    </div>

    <figcaption v-if="caption">
      <Richtext :data="{ innerHTML: caption }" />
    </figcaption>
  </figure>
</template>

<script>
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Figure',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: () => {},
    },
    disableRatio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
    };
  },
  computed: {
    settings() {
      return this.currentImg === 'mobile'
        && this.data.settings.mobile?.toggle
        && this.data.value.image_mobile
        ? this.data.settings.mobile
        : this.data.settings;
    },
    image() {
      return this.currentImg === 'mobile'
        && this.data.settings.mobile?.toggle
        && this.data.value.image_mobile
        ? this.data.value.image_mobile
        : this.data.value.image;
    },
    caption() {
      if (this.data.settings.caption) {
        return this.data.value.caption
          ? this.data.value.caption
          : this.image.caption;
      }
      return false;
    },
    themeClass() {
      return this.theme
        ? `figure--${this.theme}`
        : this.settings.theme
          ? `figure--${this.settings.theme}`
          : false;
    },
    centeredClass() {
      return this.settings.center;
    },
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);

    if (!this.lazyload) {
      // this.load();
    }
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      let src = this.image.sizes.l;

      const { sizes } = this.image;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const wrapper = this.$parent.$el.offsetWidth;
      const dimension = 'width';
      const max = wrapper * ratio;
      const ranges = {
        s: sizes[`s-${dimension}`],
        m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        xl: sizes[`xl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'l';

      this.$nextTick(() => {
        if (this.$mq.isMobile && this.data.settings.mobile?.toggle) {
          this.currentImg = 'mobile';
        } else {
          this.currentImg = 'default';
        }
        src = this.image.sizes[size];

        if (this.$el.dataset.lazy === 'true') {
          this.$refs.image.src = src;
        }

        this.src = src;
      });
    },
    async load() {
      const img = this.$refs.image;

      if (this.src) {
        img.src = this.src;
      }

      if (img && img.decode && img.src) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.figure {
  margin: 0;
  position: relative;
  width: 100%;
  --width: var(--image-w);
  --height: var(--image-h);

  .image--wrapper {
    position: relative;
    display: block;
  }

  &:not(.figure--native) &:not(.figure--full-width) {
    .image--wrapper {
      @include aspect-ratio(var(--width), var(--height));
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: 100%;
    height: 100%;
    object-fit: var(--fit, fill);
    object-position: var(--origin, 50% 50%);
    display: block;
  }

  &--native {
    .image {
      position: relative;
      position: initial;
      height: auto;
    }
  }

  &--default {
    width: auto;
    .image {
      position: relative;
      position: initial;
      max-width: 100%;
      height: 60vw;
      @include mq(s) {
        height: 80vh;
      }
    }
    .image--wrapper {
      height: 100%;
    }
  }

  &--small {
    width: auto;
    .image {
      position: relative;
      position: initial;
      width: auto;
      height: 60vw;
      @include mq(s) {
        height: 60vh;
      }
    }
    .image--wrapper {
      height: 100%;
    }
  }

  &--gallery {
    width: auto;

    .image {
      position: relative;
      position: initial;
      width: auto;
      height: 60vw;
      @include mq(s) {
        height: 60vh;
      }
    }
    .image--wrapper {
      height: 100%;
    }
  }

  &--full-width {
    .image {
      position: relative;
      position: initial;
      height: 60vw;
      width: 100%;
      @include mq(s) {
        height: 60vh;
        width: 100%;
      }
      object-fit: cover;
    }
    .image--wrapper {
      height: 100%;
    }
  }

  &--no-boxed {
    .image {
      position: relative;
      position: initial;
      height: auto;
    }
  }

  &--hero {
    height: 100%;
    position: absolute;
    z-index: -1;
    .image--wrapper {
      height: 100%;
    }
    .image {
      position: relative;
      position: initial;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--thumb {
    padding-bottom: var(--spacer-xxxs);
    .image--wrapper {
      @include aspect-ratio(3, 4);
      @include mq($from: s) {
        @include aspect-ratio(2, 1);
      }
    }
    .image {
      width: 100%;
      object-fit: cover;
    }
  }

  &--centered{
    text-align: center;
  }

  &.align--top {
    .image {
      object-position: 50% 0;
    }
  }

  &.align--center {
    .image {
      object-position: 50% 50%;
    }
  }

  &.align--bottom {
    .image {
      object-position: 50% 100%;
    }
  }

  &.figure-contain {
    .image {
      object-fit: contain;
    }
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.1s ease-out;
    will-change: opacity;

    background: var(--gray-text);
  }

  .image {
    visibility: hidden;

    &[src] {
      visibility: visible;
    }
  }
  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }
  }

  figcaption{
    padding-top: var(--spacer-xxxs);
    @include mq(s){
      padding-top: var(--spacer-xs);
    }
  }
}
</style>
