<template>
  <component
    :is="mergeProps.tag"
    :class="['title', `title--${mergeProps.typo}`]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
    typo: {
      type: String,
      default: () => {},
    },
    tag: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    mergeProps() {
      return {
        typo: this.typo ? this.typo : this.data ? this.data.settings?.typo : 'default',
        value: this.value || this.data.value?.title,
        tag: this.tag ? this.tag : this.data ? this.data.settings?.tag : 'h2',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title{
  &--default{
    @extend %typo--medium;
    @extend %typo--mobile-h1;
  }
  &--big{
    @extend %typo--big;
    @extend %typo--mobile-h1;
  }
  &--small{
    @extend %typo--p;
    @extend %typo--mobile-h2;
    &-thumb{
      @extend %typo--p;
      @extend %typo--mobile-h2;
      padding-bottom: calc(var(--spacer-xxxs) / 2 );
    }
  }
  &--thumb {
    @extend %typo--medium;
    @extend %typo--mobile-h2-less;
  }
  &--archive {
    @extend %typo--big;
    @extend %typo--mobile-h1;
    padding: var(--spacer-s) 0;
    border-top: 1px solid var(--white);
  }
  &--related {
    @extend %typo--medium;
    @extend %typo--mobile-h1;
    padding: var(--spacer-l) 0 var(--spacer-xs);
    @include mq(s) {
      padding: var(--spacer-l-t) 0 var(--spacer-xs);
    }
  }
}
</style>
