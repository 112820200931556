var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.containerClass},[_c(_vm.tag,{tag:"compoment",class:[
      'link',
      _vm.themeClass,
      [..._vm.additionalThemes],
      _vm.data.size ? `typo--${_vm.data.size}` : 'typo--button',
      _vm.disabled ? 'link--disabled' : null,
    ],attrs:{"href":_vm.external ? _vm.url : undefined,"to":!_vm.external ? _vm.url : undefined,"target":_vm.link.target,"data-active":_vm.isActive,"type":_vm.data.type ? _vm.data.type : ''},domProps:{"innerHTML":_vm._s(_vm.title)},on:_vm._d({},[_vm.event,_vm.data.fn])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }