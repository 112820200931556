var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:{ 'main--afterimage': _vm.hasVideo, 'main--exhibition': _vm.isExhibition }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"inner"},[(_vm.hasVideo && _vm.deadline)?_c('Countdown',{attrs:{"deadline":_vm.deadline}}):_vm._e(),_c('Hero',{attrs:{"data":_vm.post,"video":_vm.hasVideo,"deadline":_vm.deadline}}),(_vm.post.acf.synopsis)?_c('Wrapper',{class:['synopsis', _vm.expanded ? 'synopsis--enabled' : ''],attrs:{"boxed":""}},[_c('div',{staticClass:"synopsis--content",domProps:{"innerHTML":_vm._s(_vm.post.acf.synopsis)}}),(!_vm.expanded)?_c('span',{staticClass:"synopsis--more",on:{"click":function($event){return _vm.readMore()}}},[_vm._v("Read More")]):_vm._e()]):_vm._e(),(_vm.hasVideo && _vm.post.acf.teaser_video)?_c('div',{staticClass:"afterimage-teaser",class:_vm.pip ? 'afterimage-teaser--pip' : false},[_c('div',{ref:"videoWrapper",staticClass:"video"},[_c('Video',{ref:"teaser",attrs:{"data":{
              value: {
                vimeo: _vm.post.acf.teaser_video,
              },
              settings: {
                theme: 'hero',
                teaser: true,
              },
            }}}),((!_vm.switchVideoFull || _vm.$mq.isSmartphone) && _vm.deadline)?_c('Link',{staticClass:"play",attrs:{"data":{
              fn: _vm.switchVideo,
              title: 'Play',
            },"theme":"button"}}):_vm._e()],1)]):_vm._e(),_c('transition',{attrs:{"css":false,"mode":"out-in"},on:{"enter":_vm.openVideoFull,"leave":_vm.closeVideoFull}},[(_vm.post.acf.full_video && _vm.switchVideoFull)?_c('div',{staticClass:"afterimage-full"},[_c('Video',{ref:"full",attrs:{"data":{
              value: {
                vimeo: _vm.post.acf.full_video,
              },
              settings: {
                theme: 'hero',
                full: true,
              },
            }}}),(!_vm.$mq.isSmartphone)?_c('Link',{ref:"close",staticClass:"close",attrs:{"data":{
              fn: _vm.switchVideo,
              title: 'Close',
            },"theme":"button"}}):_vm._e()],1):_vm._e()]),(_vm.expanded)?_c('Gutenberg',{ref:"gutenberg",attrs:{"data":_vm.post.gds_blocks,"type":_vm.post.type}}):_vm._e()],1),(_vm.expanded)?_c('Wrapper',{attrs:{"boxed":""}},[_c('ThumbSeparator',{attrs:{"data":_vm.post}}),(_vm.post.acf.related && _vm.post.acf.related.length > 0)?_c('div',[_c('Archive',{attrs:{"data":{
            value: {
              title: 'Related Projects',
              post_type: 'post',
              include: _vm.post.acf.related,
              typo: 'related',
            },
            settings: {
              posts_per_page: _vm.$mq.isMobile ? 2 : _vm.post.acf.related.length,
              order: true,
            },
          },"thumb":"figure"}})],1):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }