<template>
  <main
    :class="{ 'main--afterimage': hasVideo, 'main--exhibition': isExhibition }"
  >
    <div class="container">
      <div class="inner">
        <Countdown
          v-if="hasVideo && deadline"
          :deadline="deadline"
        />

        <Hero
          :data="post"
          :video="hasVideo"
          :deadline="deadline"
        />
        <Wrapper
          v-if="post.acf.synopsis"
          boxed
          :class="['synopsis', expanded ? 'synopsis--enabled' : '']"
        >
          <div
            class="synopsis--content"
            v-html="post.acf.synopsis"
          />
          <span
            v-if="!expanded"
            class="synopsis--more"
            @click="readMore()"
          >Read More</span>
        </Wrapper>

        <div
          v-if="hasVideo && post.acf.teaser_video"
          class="afterimage-teaser"
          :class="pip ? 'afterimage-teaser--pip' : false"
        >
          <div
            ref="videoWrapper"
            class="video"
          >
            <Video
              ref="teaser"
              :data="{
                value: {
                  vimeo: post.acf.teaser_video,
                },
                settings: {
                  theme: 'hero',
                  teaser: true,
                },
              }"
            />

            <Link
              v-if="(!switchVideoFull || $mq.isSmartphone) && deadline"
              :data="{
                fn: switchVideo,
                title: 'Play',
              }"
              theme="button"
              class="play"
            />
          </div>
        </div>

        <transition
          :css="false"
          mode="out-in"
          @enter="openVideoFull"
          @leave="closeVideoFull"
        >
          <div
            v-if="post.acf.full_video && switchVideoFull"
            class="afterimage-full"
          >
            <Video
              ref="full"
              :data="{
                value: {
                  vimeo: post.acf.full_video,
                },
                settings: {
                  theme: 'hero',
                  full: true,
                },
              }"
            />

            <Link
              v-if="!$mq.isSmartphone"
              ref="close"
              :data="{
                fn: switchVideo,
                title: 'Close',
              }"
              theme="button"
              class="close"
            />
          </div>
        </transition>

        <Gutenberg
          v-if="expanded"
          ref="gutenberg"
          :data="post.gds_blocks"
          :type="post.type"
        />
      </div>

      <Wrapper
        v-if="expanded"
        boxed
      >
        <ThumbSeparator :data="post" />
        <div v-if="post.acf.related && post.acf.related.length > 0">
          <Archive
            :data="{
              value: {
                title: 'Related Projects',
                post_type: 'post',
                include: post.acf.related,
                typo: 'related',
              },
              settings: {
                posts_per_page: $mq.isMobile ? 2 : post.acf.related.length,
                order: true,
              },
            }"
            thumb="figure"
          />
        </div>
      </Wrapper>
    </div>
  </main>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { gsap } from 'gsap';
import { mapGetters } from 'vuex';

import Gutenberg from '@/components/blocks';
import Countdown from '@/components/ui/countdown';
import Hero from '@/components/hero';
import Video from '@/components/media/video';
import Link from '@/components/typo/link';
import Archive from '@/components/blocks/archive';
import ThumbSeparator from '@/components/thumb/separator';
import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Gutenberg,
    Hero,
    Archive,
    Countdown,
    ThumbSeparator,
    Video,
    Link,
  },
  mixins: [data],
  data() {
    return {
      deadline: null,
      switchVideoFull: false,
      pip: false,
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters(['transition']),
    hasVideo() {
      return this.post.acf.project && this.post.acf.project.term_id === 11;
    },
    isExhibition() {
      return this.post.acf.type && this.post.acf.type.term_id === 4;
    },
    expanded() {
      return this.isExpanded || !(this.post.acf.synopsis.length > 0);
    },
  },
  watch: {
    pip(val) {
      const { videoWrapper } = this.$refs;
      if (val) {
        setTimeout(() => {
          videoWrapper.classList.add('anim');
          this.$nextTick(() => {
            videoWrapper.classList.add('visible');
          });
        }, 1);
      } else {
        videoWrapper.classList.remove('anim');
        videoWrapper.classList.remove('visible');
      }
    },
  },
  created() {
    if (this.hasVideo) {
      const { until, from } = this.post.acf;
      const deadline = until.replace(/-/g, '/');
      const since = from.replace(/-/g, '/');
      const now = Date.parse(new Date());
      if (Date.parse(since) <= now && Date.parse(deadline) > now) {
        this.deadline = deadline;
      }
    }
  },
  mounted() {
    if (this.deadline && this.post.acf.teaser_video) {
      this.$aion.add(this.checkPip, 'checkPip', true);
    }
  },
  beforeDestroy() {
    this.$aion.remove('checkPip');
  },
  methods: {
    // disablePipBefore() {
    //   return ;
    // },
    checkPip() {
      if (this.$mq.isMobile || this.transition || !this.expanded) return;
      this.pip = window.pageYOffset > window.innerHeight
        && window.pageYOffset < this.$refs.gutenberg.$el.clientHeight + 400;
    },
    switchVideo() {
      this.switchVideoFull = !this.switchVideoFull;
    },
    openVideoFull(el, done) {
      if (this.$mq.isSmartphone) {
        this.$nextTick(() => {
          if (this.$refs.full) {
            this.$refs.teaser.plyr.pause();

            if (!this.$mq.isIOS) {
              this.$refs.full.$refs.player.requestFullscreen().then(() => {
                this.$refs.full.plyr.play();
              });
            } else {
              this.$refs.full.plyr.play();
            }

            this.$refs.full.plyr.media.addEventListener(
              'fullscreenchange',
              this.exitFullScreen,
            );
            this.$refs.full.plyr.media.addEventListener(
              'webkitendfullscreen',
              this.exitFullScreen,
            );
          }
        });
      } else {
        this.$store.commit('HIDE_HEADER', this.switchVideoFull);

        disableBodyScroll(this.$el);
        this.$nextTick(() => {
          this.$refs.teaser.plyr.pause();
          this.$refs.full.plyr.play();
        });
      }

      gsap.set(el, { yPercent: 100 });

      gsap.to(el, { yPercent: 0, duration: 0.4, ease: 'custom' }).then(() => {
        done();
      });
    },
    closeVideoFull(el, done) {
      const { hls, plyr } = this.$refs.full;

      gsap.to(el, { yPercent: 100, duration: 0.4, ease: 'custom' }).then(() => {
        clearAllBodyScrollLocks();

        this.$store.commit('HIDE_HEADER', this.switchVideoFull);

        if (hls) hls.destroy();
        if (plyr) plyr.destroy();

        this.$nextTick(() => {
          this.$refs.teaser.plyr.play();
        });

        done();
      });
    },
    exitFullScreen() {
      if (!document.fullscreenElement) {
        if (this.$mq.isSmartphone) {
          this.$refs.full.plyr.media.removeEventListener(
            'fullscreenchange',
            this.exitFullScreen,
          );
          this.$refs.full.plyr.media.removeEventListener(
            'webkitendfullscreen',
            this.exitFullScreen,
          );
        }

        this.$nextTick(() => {
          this.$refs.teaser.plyr.play();
        });

        this.switchVideoFull = false;
      }
    },
    readMore() {
      this.isExpanded = true;
      this.$nextTick(() => {
        this.$root.$children[0].lazyObserve(this.$el);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: var(--gray);
  min-height: calc(100 * var(--vh, 1vh));
}

.afterimage-teaser {
  position: absolute;
  width: 100%;
  top: 0;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100 * var(--vh, 1vh));

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 3;
    }
  }

  &--pip {
    @include mq(s) {
      position: sticky;
      top: calc((100 * var(--vh, 1vh)) - var(--spacer-s));
      height: 0;
      width: 20vw;
      z-index: 2;
      margin-left: auto;
      margin-right: var(--spacer-s);

      .video {
        height: auto;
        aspect-ratio: 16 / 9;
        border-radius: 30px;
        overflow: hidden;
        transform: translateY(var(--spacer-s));

        &.anim {
          transform: translateY(0%);
          transition: transform 0.3s $ease-custom;
        }

        &.visible {
          transform: translateY(-100%);
        }

        .play {
          bottom: auto;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
}

.afterimage-full {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .close {
    position: absolute;
    top: var(--spacer-s);
    right: var(--spacer-s);
    z-index: 11;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;

    &:hover {
      opacity: 1 !important;
    }
  }
}

.inner {
  position: relative;
}

.synopsis {
  padding-top: var(--spacer-xs);
  padding-bottom: var(--spacer-xs);
  @extend %typo--rare;
  // &--enabled {
  //   // padding-bottom: 0;
  // }
  &--content {
    display: inline;
    ::v-deep p {
      display: inline;
    }
  }
  &--more {
    cursor: pointer;
    text-decoration: none;
    color: var(--black);
  }
}
.main--afterimage,
.main--exhibition {
  .synopsis {
    &--more {
      color: var(--white);
    }
  }
}
</style>
