import difference from 'lodash.difference';
import orderBy from 'lodash.orderby';

import {
  fetchItems,
  fetchSingle,
  fetchSearch,
  sendAjax,
  fetchSingleById,
  fetchRsvp,
} from '@/api/wp';

export default {
  async getSingleById(_, { type, id }) {
    const response = await fetchSingleById({
      type,
      params: { id },
    }).then(({ data }) => data);
    return response;
  },
  async getSingleBySlug(
    { commit, getters },
    {
      type, slug, lang, context, password,
    },
  ) {
    let response = null;
    const responseFromState = getters.singleBySlug({
      type,
      slug,
      lang,
      context,
    });
    if (!responseFromState) {
      await fetchSingle({
        type,
        params: {
          slug,
          lang,
          context,
          password,
        },
      }).then(({ data: [item] }) => {
        commit('ADD_ITEM', {
          type,
          item,
          lang,
          context,
        });
        response = item;
      });
    } else {
      response = responseFromState;
    }
    return response;
  },
  async getItems(
    { getters, commit },
    { type, params, cancelToken = false },
  ) {
    let response = null;

    const responseFromState = getters.request({ type, params });
    const responseWithParams = responseFromState
      && params.slug
      && responseFromState.length !== params.slug.length;

    if (!responseFromState || responseWithParams) {
      let itemsFromStore = [];
      let prevParamsSlug = null;
      if (responseWithParams) {
        prevParamsSlug = params.slug;
        const slugFromStore = difference(
          params.slug,
          responseFromState,
        );
        Object.assign(params, { slug: slugFromStore });
        itemsFromStore = responseFromState.map((slug) => getters.singleBySlug({ type, slug, lang: params.lang }));
      }
      await fetchItems({ type, params, cancelToken }).then(
        ({
          data: items,
          headers: {
            'x-wp-total': total,
            'x-wp-totalpages': totalPages,
          },
        }) => {
          items.forEach((item) => commit('ADD_ITEM', {
            type,
            item: {
              ...item,
              agenda: params.agenda,
            },
            lang: params.lang,
            context: params.context,
          }));
          commit('ADD_REQUEST', {
            type,
            request: {
              type,
              params,
              total: parseInt(total, 10),
              totalPages: parseInt(totalPages, 10),
              data: items.map((i) => i.slug),
            },
          });

          const sortResponse = responseWithParams
            ? orderBy(
              [...items, ...itemsFromStore],
              [(i) => prevParamsSlug.findIndex((e) => e === i.slug)],
            )
            : items;

          const parseResponse = params.per_page
            ? sortResponse.slice(0, params.per_page)
            : sortResponse;

          response = parseResponse;
        },
      );
    } else {
      const parseResponseFromState = params.per_page
        ? responseFromState.slice(0, params.per_page)
        : responseFromState;
      response = parseResponseFromState.map((slug) => getters.singleBySlug({
        type,
        slug,
        lang: params.lang,
        context: params.context,
      }));
    }

    return response;
  },
  getSearch(_, request) {
    return fetchSearch(request);
  },
  sendRequest(_, request) {
    return sendAjax(request);
  },
  async getRsvp() {
    return fetchRsvp();
  },
  async getAgenda({ dispatch }) {
    const current = await dispatch('getItems', {
      type: 'posts',
      params: {
        per_page: 24,
        offset: 0,
        order: 'desc',
        lang: 'default',
        agenda: 'current',
        context: 'embed',
        orderby: 'acf.from',
        search: undefined,
        include: undefined,
        _fields:
          'id, date, slug, type, link, title, acf.title, acf.from, acf.until, acf.artist, acf.symbol, acf.image, acf.project, acf.type',
      },
    });
    const upcoming = await dispatch('getItems', {
      type: 'posts',
      params: {
        per_page: 24,
        offset: 0,
        order: 'desc',
        lang: 'default',
        agenda: 'upcoming',
        context: 'embed',
        orderby: 'acf.from',
        search: undefined,
        include: undefined,
        _fields:
          'id, date, slug, type, link, title, acf.title, acf.from, acf.until, acf.artist, acf.symbol, acf.image, acf.project, acf.type',
      },
    });
    const past = await dispatch('getItems', {
      type: 'posts',
      params: {
        per_page: 24,
        offset: 0,
        order: 'desc',
        lang: 'default',
        agenda: 'past',
        context: 'embed',
        orderby: 'acf.from',
        search: undefined,
        include: undefined,
        _fields:
          'id, date, slug, type, link, title, acf.title, acf.from, acf.until, acf.artist, acf.symbol, acf.image, acf.project, acf.type',
      },
    });

    return {
      current,
      upcoming,
      past,
    };
  },
};
