var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:`menu btn-container ${_vm.name ? 'menu--' + _vm.name : 'menu--navigation'}`},[(_vm.name === 'navigation')?_c('Link',{staticClass:"link-home",attrs:{"data":{
      url: '/',
      title: _vm.$store.state.wp.site.name,
    },"theme":"button","has-container":false,"additional-themes":[_vm.name === 'navigation' ? 'link--button-menu' : '']}}):_vm._e(),_vm._l((_vm.navigation),function(item){return _c('Link',{key:item.id,class:[...item.css],attrs:{"data":{
      url: item.url,
      title: item.content,
      target: item.target,
    },"theme":"button","has-container":false,"additional-themes":[_vm.name === 'navigation' ? 'link--button-menu' : '']}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }