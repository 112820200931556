<template>
  <router-link
    :to="$relativeUrl(data.link)"
    :class="`thumb thumb--default t-center`"
  >
    <inline-svg
      v-if="data.acf.symbol"
      :src="data.acf.symbol"
      class="symbol"
      @loaded="fillPath($event)"
    />
    <Title
      v-if="data.acf.project.name || data.acf.type.name"
      :value="data.acf.project.name ? data.acf.project.name : data.acf.type.name"
      typo="small-thumb"
    />
    <Title
      v-if="data.acf.artist"
      :value="data.acf.artist"
      class="t-uppercase"
    />
    <Title
      v-if="data.acf.title"
      :value="data.acf.title"
      class="t-uppercase"
    />
    <!-- change as label -->
    <Title :value="parseDate(data.acf.from, data.acf.until)" />
  </router-link>
</template>

<script>
// import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import { parseDate, fillPath } from '@/assets/js/utils';

export default {
  name: 'Thumb',
  components: {
    // Figure,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      parseDate,
      fillPath,
    };
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;
  text-decoration: none;

  &--default {
    svg {
      fill: var(--black);
    }
    color: white;
  }
}
</style>
