var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-component"},[_c('div',{ref:"scroller",staticClass:"homepage"},[_c('div',{staticClass:"item t-uppercase t-center"},[_c('div',{staticClass:"item__text u-hidden--from-s"},[_c('div',{staticClass:"item__text--content"},[(_vm.home.text)?_c('Title',{attrs:{"value":_vm.home.text,"typo":"big"}}):_vm._e()],1)]),(_vm.home.symbol)?_c('div',{ref:"icon",staticClass:"item__icon item__icon--logo",style:(`--maskUrl: url(${_vm.home.symbol}); --maskClip: none; --maskColor: white; --maskBackground: transparent`)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.home.symbol),expression:"home.symbol"}],staticClass:"item__icon--content"})]):_vm._e()]),_vm._l((_vm.data),function(item,index){return _c('router-link',{key:index,staticClass:"item--link",attrs:{"to":_vm.$relativeUrl(item.link)}},[_c('div',{ref:"item",refInFor:true,staticClass:"item t-center"},[(item.acf.teaser_video)?_c('Video',{ref:"teaser",refInFor:true,staticClass:"item__image",attrs:{"data":{
            value: {
              vimeo: item.acf.teaser_video,
            },
            settings: {
              theme: 'hero',
              teaser: true,
            },
          }}}):(item.acf.image)?_c('Figure',{staticClass:"item__image",attrs:{"data":{
            value: {
              image: item.acf.image,
            },
            settings: {
              theme: 'hero',
            },
          },"lazyload":false}}):_vm._e(),_c('div',{staticClass:"item__text"},[_c('div',{ref:"paragraph",refInFor:true,staticClass:"item__text--content"},[(item.acf.project.name || item.acf.type.name)?_c('Title',{attrs:{"value":item.acf.project.name
                  ? item.acf.project.name
                  : item.acf.type.name,"typo":"small-thumb"}}):_vm._e(),(item.acf.artist)?_c('Title',{staticClass:"t-uppercase",attrs:{"value":item.acf.artist,"typo":"big"}}):_vm._e(),(item.acf.title)?_c('Title',{staticClass:"t-uppercase",attrs:{"value":item.acf.title,"typo":"big"}}):_vm._e(),_c('Title',{staticClass:"t-uppercase",attrs:{"value":_vm.parseDate(item.acf.from, item.acf.until),"typo":"big"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.acf.symbol),expression:"item.acf.symbol"}],ref:"icon",refInFor:true,staticClass:"item__icon",style:(`--maskUrl: url(${item.acf.symbol});
        ${
            item.acf.project.slug === 'afterimage'
              ? '--maskColor: black; --maskBackground: black;'
              : ''
          }
        ${
            !item.acf.project.slug && item.acf.type.slug === 'exhibition'
              ? '--maskClip: none; --maskColor: black; --maskBackground: transparent'
              : ''
          }
        ${
            item.acf.project.slug === 'artifact'
              ? '--maskClip: none; --maskColor: white; --maskBackground: transparent;'
              : ''
          }
        ${
            item.acf.project.slug === 'billboard' ||
            (!item.acf.project.slug && (item.acf.type.slug === 'digital' || item.acf.type.slug === 'event'))
              ? '--maskColor: white; --maskBackground: white;'
              : ''
          }
        `)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.acf.symbol),expression:"item.acf.symbol"}],staticClass:"item__icon--content"})])],1)])}),_c('AppFooter',{staticClass:"homepage--footer"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }